import React, {useEffect, useRef, useState} from "react";
import {addClassName} from "utils/general";
import SCSSModule from "./InputNumeric.module.scss";
import Form from 'react-bootstrap/Form';

function InputNumeric({
    suffix = undefined,
    onNumberInput = undefined,
    onNumberChange = undefined,
    placeholder = undefined,
    ...rest
}: InputNumericProps): React.ReactElement {
    const defaultValue = rest.defaultValue ? getFormattedValue(Number(rest.defaultValue)) + getSuffix() : "";
    const inputDOM = useRef<HTMLInputElement>(null!);
    const [value, setValue] = useState<string | undefined>(defaultValue);
    const [currentPlaceholder, setCurrentPlaceholder] = useState<string | undefined>(placeholder);
    let formattedValueRef = useRef<string>("");

    useEffect(() => {
        // IMPORTANT: Causes major issues on Safari if we change the selection range
        // of an out of focus field
        if (inputDOM && document.activeElement === inputDOM.current) {
            inputDOM.current.setSelectionRange(formattedValueRef.current.length, formattedValueRef.current.length);
        }
    }, [value]);

    rest.className = addClassName(rest.className, SCSSModule.self);

    function getLastDigitPos(str: string): number {
        const regex1 = new RegExp(/\d+/g);
        let lastPos = 0;

        do {
            regex1.test(str);
            lastPos = lastPos < regex1.lastIndex ? regex1.lastIndex : lastPos;
        } while (regex1.lastIndex);

        return lastPos;
    }

    function getNumericValue(input: HTMLInputElement): number | false {
        const numericValue = parseInt(input.value.replace(/\D/g, ""));
        return (Number.isInteger(numericValue)) ? numericValue : false;
    }

    function getSuffix(): string {
        return suffix ?? "";
    }

    function getFormattedValue(numericValue: number | false): string {
        return numericValue.toLocaleString();
    }

    function handleInput(event: React.FormEvent<HTMLInputElement>) {
        const numericValue = getNumericValue(event.currentTarget);

        if (Number.isInteger(numericValue)) {
            const formattedValue = getFormattedValue(numericValue);
            setValue(formattedValue + getSuffix());
            formattedValueRef.current = formattedValue;
        } else {
            setValue("");
            event.currentTarget.value = "";
        }

        if (onNumberInput) {
            onNumberInput(numericValue);
        }

        event.stopPropagation();
    }

    function handleFocus(event: React.BaseSyntheticEvent) {
        const cursor = getLastDigitPos(event.currentTarget.value);

        setCurrentPlaceholder("");

        setTimeout(
            function (this: HTMLInputElement, cursor: number) {
                this.setSelectionRange(cursor, cursor);
            }.bind(event.currentTarget, cursor),
            0
        );
    }

    /**
     * React's onChange is actually fired via the browser's onInput
     * So we should use this with onBlur instead to differentiate the two events
     */
    function handleRealChange(event: React.BaseSyntheticEvent) {
        const numericValue = getNumericValue(event.currentTarget);

        if (numericValue) {
            setValue(getFormattedValue(numericValue) + getSuffix());
        }

        setCurrentPlaceholder(placeholder);

        if (onNumberChange) {
            onNumberChange(numericValue);
        }
    }

    return <>
        <Form.Control type="text" value={value} ref={inputDOM} onInput={handleInput}
                      onFocus={handleFocus} onBlur={handleRealChange}
                      placeholder={currentPlaceholder} {...rest}/>
    </>;
}

export default InputNumeric;
