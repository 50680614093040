import React, {forwardRef} from "react";
import SCSSModule from "./ButtonIconText.module.scss";
import MaterialSymbolRound from "../MaterialSymbolRound/MaterialSymbolRound";
import Button from "react-bootstrap/Button";
import {addClassName} from "utils/general";

const ButtonIconText = forwardRef(
    ({symbol, title, ...rest}: ButtonIconTextProps, ref:React.ForwardedRef<HTMLDivElement>): React.ReactElement => {
        rest.className = addClassName(rest.className, SCSSModule.self);

        return <Button ref={ref} variant={"info"} {...rest}>
            <MaterialSymbolRound value={symbol}/>
            <span>{title}</span>
        </Button>
    }
);

export default ButtonIconText;