const isProd = document.location.host.includes("pae-engineers.com");
const targetOrigin = isProd ? "https://portlandgeneral.com/" : "https://content-pre-p1-dot-gcp-csweb-dev-d78c.appspot.com/";
const targetURL = `${targetOrigin}builders-new-construction/electric-service-commercial-builders/energy-and-emissions-estimator`;

const pge = {
    isProd: isProd,
    targetOrigin: targetOrigin,
    targetURL: targetURL,
}

export default pge;