import React from "react";
import {Card} from "react-bootstrap";
import SCSSModule from "./TreesCard.module.scss";
import {TreeUnit} from "types/InputProcessorType";

function TreesCard({title, value, unit}: TreesCardProps): React.ReactElement {
    const valueSuffix = unit === TreeUnit.trees ? " trees planted" : " acres of forest ";

    return (
        <Card className={SCSSModule["trees-card"]}>
            <Card.Body>
                <Card.Title>{title}</Card.Title>
                <Card.Text className={unit === TreeUnit.acres ? SCSSModule["acres"] : ""}>
                    {Math.floor(value).toLocaleString()} {valueSuffix}
                </Card.Text>
            </Card.Body>
        </Card>
    );
}

export default TreesCard;