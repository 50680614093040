import React from "react";
import {useStepContext} from "contexts/StepContext";
import SCSSModule from "./StepVisualizer.module.scss";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import ContentRow from "components/helpers/Grid/ContentRow";
import AncillaryContentModal from "../AncillaryContentModal/AncillaryContentModal";

function StepVisualizer(): React.ReactElement | null {
    const currentStep = useStepContext();
    const currentStepNum = currentStep as number;

    const visualizedSteps = new Map([
        [1, {label: "Building type", width: "6.5rem"}],
        [2, {label: "Building details", width: "7.25rem"}],
        [3, {label: "Solar", width: "3.75rem"}],
        [4, {label: "Solar details", width: "6.125rem"}],
        [5, {label: "Systems", width: "4.875rem"}],
        [6, {label: "Specific adjustments", width: "9rem"}]
    ]);

    if (Array.from(visualizedSteps.keys()).indexOf(currentStepNum) === -1) {
        return null;
    }

    return <Container>
        <ContentRow rowClassName={SCSSModule["step-visualizer-row"]}>
            <Col md={6} xl={7} className={"col-9 ps-md-0"}>
                <div className={SCSSModule["step-visualizer"]}>
                    <span className={SCSSModule["progress-label"]} style={{width: visualizedSteps.get(currentStepNum)?.width}}>{visualizedSteps.get(currentStepNum)?.label}</span>
                    <div className={SCSSModule["step-container"]}>
                        {[...Array(6)].map((x, i) =>
                            <span key={i} className={[
                                SCSSModule["step"],
                                currentStepNum >= i+1 ? SCSSModule["previous"] : SCSSModule["next"],
                                currentStepNum === i+1 ? SCSSModule["current"] : ""
                            ].filter(Boolean).join(" ")} />
                        )}
                        <span className={SCSSModule["marker"]+" "+SCSSModule[`current${currentStepNum}`]}></span>
                    </div>
                </div>
            </Col>
            <Col md={2} xl={1} className={"col-3 pe-md-0 "}>
                <AncillaryContentModal currentStep={currentStep} />
            </Col>
        </ContentRow>
    </Container>;
}

export default StepVisualizer;