const parentRef = Symbol('has-polyfill-parent-ref');
export const hasPolyfill = (input, parentSelector) => {
    if (typeof input[parentRef] === "undefined") {
        input[parentRef] = input.closest(parentSelector);
    }

    const parent = input[parentRef];
    if (input.checkValidity()) {
        parent.classList.remove('has-polyfill-invalid');
    } else {
        parent.classList.add('has-polyfill-invalid');
    }
}