type Timeout = {
    callback: () => void,
    runAfterTime: number
}

type Interval = Timeout & {
    interval: number
}

let timeouts: Map<Symbol, Timeout> = new Map();
let timeoutRAFRunning: boolean = false;
let intervals: Map<Symbol, Interval> = new Map();
let intervalRAFRunning: boolean = false;

const startTimeoutRAF = function (): void {
    const currentTime = Date.now();
    let numTimeoutsRemaining = 0;
    let timeoutsToExecute: Set<() => void> = new Set();

    for (const [timeoutId, timeout] of timeouts.entries()) {
        if (timeout.runAfterTime <= currentTime) {
            timeoutsToExecute.add(timeout.callback);
            timeouts.delete(timeoutId);
        } else {
            numTimeoutsRemaining++;
        }
    }

    if (numTimeoutsRemaining) {
        requestAnimationFrame(startTimeoutRAF);
    } else {
        timeoutRAFRunning = false;
    }

    if (timeoutsToExecute.size) {
        for (const callback of timeoutsToExecute) {
            callback();
        }
    }
};

const startIntervalRAF = function (): void {
    if (intervals.size) {
        requestAnimationFrame(startIntervalRAF);
    } else {
        intervalRAFRunning = false;
        return;
    }

    const currentTime = Date.now();
    let intervalsToExecute: Set<() => void> = new Set();

    for (const [intervalId, interval] of intervals.entries()) {
        if (interval.runAfterTime <= currentTime) {
            intervalsToExecute.add(interval.callback);
            interval.runAfterTime = Date.now() + parseInt(interval.interval.toString());
            // console.log(interval.interval, "next call time is " + interval.runAfterTime);
            intervals.set(intervalId, interval);
        }
    }

    for (const callback of intervalsToExecute) {
        callback();
    }
};

const RAF = {
    setTimeout: function (callback: () => void, time: number) {
        const timeoutId = Symbol();

        timeouts.set(timeoutId, {
            callback: callback, runAfterTime: Date.now() + parseInt(time.toString())
        });

        if (!timeoutRAFRunning) {
            // This is the first timeout, so we start the RAF loop here
            requestAnimationFrame(startTimeoutRAF);
            timeoutRAFRunning = true;
        }

        return timeoutId;
    },

    clearTimeout: function (timeoutId: Symbol) {
        if (timeouts.has(timeoutId)) {
            console.debug("clearing timeout");
        } else {
            console.warn("timeout not found");
        }
        timeouts.delete(timeoutId);
    },

    setInterval: function (callback: () => void, time: number) {
        const intervalId = Symbol();

        intervals.set(intervalId, {
            callback: callback,
            interval: parseInt(time.toString()),
            runAfterTime: Date.now() + parseInt(time.toString())
        });

        if (!intervalRAFRunning) {
            // This is the first interval, so we start the RAF loop here
            requestAnimationFrame(startIntervalRAF);
            intervalRAFRunning = true;
        }

        return intervalId;
    },

    clearInterval: function (intervalId: Symbol) {
        if (intervals.has(intervalId)) {
            console.debug("clearing interval");
        } else {
            console.warn("interval not found");
        }
        intervals.delete(intervalId);
    },
};

export default RAF;