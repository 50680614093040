import React from "react";
import "./styles/App.scss";
import {CalculatorInputContextProvider} from "contexts/CalculatorInputContext";
import {ResultsContextProvider} from "contexts/ResultsContext";
import ResultsSwitcher from "components/helpers/ResultsSwitcher";

function App() {
    return (
        <>
            <ResultsContextProvider>
                <CalculatorInputContextProvider>
                    <ResultsSwitcher/>
                </CalculatorInputContextProvider>
            </ResultsContextProvider>
        </>
    );
}

export default App;
