import React from "react";
import {Card} from "react-bootstrap";
import SCSSModule from "./SavedForestsCard.module.scss";
import {TreeUnit} from "types/InputProcessorType";
import ButtonOutlineIcon from "components/common/ButtonOutlineIcon/ButtonOutlineIcon";
import getBuildingTypeClass from "components/helpers/GetBuildingTypeClass";
import {useCalculatorInputContext} from "contexts/CalculatorInputContext";
import {addClassName} from "../../../../utils/general";

function SavedForestsCard({title, value, unit, selectAllERMs}: SavedForestsCard): React.ReactElement {
    const valueSuffix = unit === TreeUnit.trees ? " trees" : " acres";
    const calculatorInput = useCalculatorInputContext();

    return (
        <Card className={addClassName(SCSSModule["saved-forests-card"], getBuildingTypeClass(calculatorInput.BuildingInputContextValue!.BuildingTypeId!))}>
            <Card.Body>
                <Card.Title>
                    {title}
                    <ButtonOutlineIcon symbol={"add"} title={"Apply"} onClick={selectAllERMs}/>
                </Card.Title>
                <Card.Text>
                    {Math.floor(value).toLocaleString()} {valueSuffix}
                </Card.Text>
            </Card.Body>
        </Card>
    );
}

export default SavedForestsCard;