import * as SFFetch from "./salesforce.fetch";
import pge from "./pge";

export type OAuthToken = string;

export type OAuthSuccess = {
    "access_token": string,
    "signature": string,
    "scope": "api",
    "instance_url": string,
    "id": string,
    "token_type": "Bearer",
    "issued_at": string,
}

export type OAuthError = {
    "error": "invalid_client_id" | string,
    "error_description": "client identifier invalid" | string,
}

export type OAuthResponse = OAuthSuccess | OAuthError;

export const OAuthResponseIsError = (response: OAuthResponse): response is OAuthError => "error" in response;

export class Factory {
    #lsKey = pge.isProd ? "pae-prod-key" : "pae-key";
    #token?:OAuthToken = undefined;

    #requestToken = async ():Promise<OAuthResponse> => {
        const headers:HeadersInit = {
            "Content-Type": "application/x-www-form-urlencoded",
        };

        const body = {
            grant_type: "client_credentials",
            client_id: pge.isProd
                ? "3MVG9oNqAtcJCF.HztucQhXlO67.BNBBk_orNgdpGZ3gd_qEW5DBk4bzRdCWUvyI_hjZn0AR.EBPJIgqVPSIQ"
                : "3MVG91Cqi3bGIrUNEufWRNG0nON70ThqF3bOmV04QVsHGqPrUSYKEP1Brh1xlHZ7S2.sETbqVgtMBZMv.Vs14",
            client_secret: pge.isProd
                ? "F149F56B1137C2EC74F6E6962DCC2B1DD80E6135AD2472682EA128CE2CCF7119"
                : "CB639079BBA935295CE2D1BDAF3311B5BC9488EB5653B2DF87921C511CC55591",
        } as SFFetch.BodyInput;

        return await SFFetch.Fetch({
            path: SFFetch.LeadPath.OauthToken,
            body: body,
            headers: headers
        }) as Promise<OAuthResponse>;
    }

    getToken = async ():Promise<string> => {
        if (!this.#token) {
            const lsToken = localStorage.getItem(this.#lsKey);
            if (lsToken) {
                console.debug(`Found token ${lsToken} in the localStorage`);
                this.#token = lsToken;
            } else {
                const response:OAuthResponse = await this.#requestToken() as unknown as OAuthResponse;

                if (OAuthResponseIsError(response)) {
                    // OAuthError
                    throw new Error(`Couldn't obtain an OAuth token.`, {cause: response});
                } else {
                    console.debug(`Got new token by fetch: ${response.access_token}`)
                    // OAuthResponse
                    localStorage.setItem(this.#lsKey, response.access_token);
                    this.#token = response.access_token;
                }
            }
        }

        return this.#token;
    }

    unsetToken = ():void => {
        this.#token = undefined;
        localStorage.removeItem(this.#lsKey);
        console.debug(`Unset token by key ${this.#lsKey} in the localStorage`);
    }
}