import React from "react";
import {ButtonProps} from "react-bootstrap";
import Button from "react-bootstrap/Button";

function PseudoDisablableButton(props:ButtonProps): React.ReactElement {
    if (props.disabled) {
        props = {
            ...props,
            disabled: false,
            className: props.className ? props.className.concat(" disabled") : "disabled",
            style: {
                ...props.style ?? {},
                pointerEvents:'initial'
            }
        }
    }
    return <Button {...props}>{props.children}</Button>;
}

export default PseudoDisablableButton;
