import React from "react";
import SCSSModule from "./Disclosures.module.scss";

function Disclosures(): React.ReactElement {

    return (
        <div className={SCSSModule["disclosures"]}>
            <p className={'mt-0'}>
                Disclosures:
            </p>
            <p className={SCSSModule["no-padding"]}>
                The information provided is based on estimates only. These estimates are provided for educational and informational purposes only and are not intended to be used as an actual calculation of GHG emissions.  Any specific project decisions should be verified with qualified engineering, construction and financial teams.
            </p>
            <p>
                Emissions estimates utilize a per MWh emissions intensity which was calculated by dividing GHG retail emissions by retail MWhs published in PGE's 2023 Clean Energy Plan and Integrated Resource Plan. These values are based on energy associated with retail customers within the State of Oregon, as required by the Oregon Department of Environmental Quality (ODEQ). PGE is required by Oregon's House Bill 2021 to achieve certain GHG reduction targets for emissions from power associated with retail customers. These targets include an 80% reduction from baseline retail emissions by 2030, 90% by 2035 and 100% by 2040. It is important to note that PGE is mandated by House Bill 2021 to reach specific volumes of MTCO2e in compliance years 2030, 2035, and 2040. HB 2021 does not require PGE to achieve a specific emissions intensity in these years. As such, these emission intensities are estimated and may vary materially from the actual MTCO2e intensity of PGE's retail load for these years. As disclosed in PGE's CEP and IRP, PGE's planned path to emissions targets features a linear decline in emissions associated with sales to Oregon retail customers from 2026 – 2030 and 2030 – 2040. Actual reductions may vary year-by-year due to variables that impact emissions that are beyond PGE's control and/or the pace of clean energy acquisition and integration. Annual CO2e intensity estimates may differ from monthly or hourly values.
            </p>
            <p>
                For more information on PGE's resource planning process, refer to this link: <a href="https://portlandgeneral.com/about/who-we-are/resource-planning/combined-cep-and-irp" target="_blank" rel="noreferrer">https://portlandgeneral.com/about/who-we-are/resource-planning/combined-cep-and-irp</a>
            </p>
            <p>
                Disclaimers:
            </p>
            <p className={SCSSModule["no-padding"]}>
                Results are based on prototypical building models with regional weather profiles for comparative purposes only.
                Results are not intended to be representative of actual building operations and should not be used for budgeting, design, construction, commissioning or ESG accounting purposes.
            </p>
            <p>
                References:
            </p>
            <p className={SCSSModule["no-padding"]}>
                Emission Equivalency: <a href="https://www.epa.gov/energy/greenhouse-gases-equivalencies-calculator-calculations-and-references" target="_blank" rel="noreferrer">https://www.epa.gov/energy/greenhouse-gases-equivalencies-calculator-calculations-and-references</a>
            </p>
            <p className={SCSSModule["no-padding"]}>
                NIST: <a href="https://pages.nist.gov/eerc/" target="_blank" rel="noreferrer">https://pages.nist.gov/eerc/</a>
            </p>
            <p className={SCSSModule["no-padding"]}>
                Prototypical Models: <a href="https://www.energycodes.gov/prototype-building-models" target="_blank" rel="noreferrer">https://www.energycodes.gov/prototype-building-models</a>
            </p>
            <p className={SCSSModule["no-padding"]}>
                Modeling Framework: <a href="https://rtf.nwcouncil.org/commercial-building-simulation-models/" target="_blank" rel="noreferrer">https://rtf.nwcouncil.org/commercial-building-simulation-models/</a>
            </p>
            <p className={SCSSModule["no-padding"]}>
                Modeled Energy Code: <a href="https://www.oregon.gov/bcd/codes-stand/Documents/2021oeesc.pdf" target="_blank" rel="noreferrer">https://www.oregon.gov/bcd/codes-stand/Documents/2021oeesc.pdf</a>
            </p>
            <p className={SCSSModule["no-padding"]}>
                PGE 2023 IRP: <a href="https://portlandgeneral.com/about/who-we-are/resource-planning/combined-cep-and-irp" target="_blank" rel="noreferrer">https://portlandgeneral.com/about/who-we-are/resource-planning/combined-cep-and-irp</a>
            </p>
        </div>
    );
}

export default Disclosures;