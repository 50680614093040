import React, {useEffect, useState} from "react";

function GraphFontMath({refSVG}:{refSVG:React.MutableRefObject<null | HTMLDivElement>}): {graphFontSize: number} {
    const SVGContainer = refSVG?.current;
    const [screenWidth, setScreenWidth] = useState<number | undefined>();
    const [svgTrueWidth, setSvgTrueWidth] = useState<number>(0);

    let [graphFontSize, setGraphFontSize] = useState<number>(14);

    useEffect(() => {
        if (screenWidth) {
            if (SVGContainer && screenWidth >= 768) {
                setSvgTrueWidth(SVGContainer?.['offsetWidth']);
                document.querySelector('.VictoryContainer > svg')?.setAttribute("viewBox", "0 0 750 300");
                const svgBaseWidth:number = parseInt(document.querySelector('.VictoryContainer svg')?.getAttribute("width") ?? "1");

                if (svgBaseWidth < svgTrueWidth) {
                    const fontSize = 20 / (svgTrueWidth/svgBaseWidth);

                    if (fontSize < 14) {
                        console.log('Change font: ', fontSize);
                        setGraphFontSize(fontSize ); //[designed text size] / ([true SVG width] / [SVG base width])
                    }
                }
            } else if (screenWidth < 768 && graphFontSize !== 14) {
                document.querySelector('.VictoryContainer > svg')?.setAttribute("viewBox", "0 0 400 300");
                setGraphFontSize(14);
            }
        }
    }, [SVGContainer, screenWidth, graphFontSize, svgTrueWidth])

    useEffect(() => {
        const onResize = () => {
            setScreenWidth(window.innerWidth);
        }

        window.addEventListener("resize", onResize);

        // Trigger on first render
        if (!screenWidth) {
            setScreenWidth(window.innerWidth);
        }

        return () => {
            window.removeEventListener("resize", onResize);
        }
    }, [screenWidth]);

    return {
        graphFontSize
    };
}

export default GraphFontMath;
