import React from "react";
import {Card} from "react-bootstrap";
import SCSSModule from "./CtaPanel.module.scss";
import Button from "react-bootstrap/Button";

function CtaPanel({content, cta, onClick}: CtaPanelProps): React.ReactElement {

    return (
        <Card className={SCSSModule["cta-panel"]}>
            <Card.Body>
                <Card.Text>{content}</Card.Text>
                <Button variant="primary" className={"on-yellow"} onClick={onClick}>{cta}</Button>
            </Card.Body>
        </Card>
    );
}

export default CtaPanel;