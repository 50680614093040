import React, {createContext, useContext, useEffect, useReducer} from "react";
import {useCalculatorInputDispatchContext} from "./CalculatorInputContext";

type SolarAction = {
    type: "useSolar" | "dontUseSolar"
}

function solarContextReducer(currentSolarSelection: boolean|undefined, action: SolarAction): boolean|undefined {
    /*
     * NOTE: Too simple to require unit testing
     */
    switch (action.type) {
        case "useSolar":
            return true;
        case "dontUseSolar":
            return false;
        default:
            return undefined;
    }
}

const defaultSolarSelection = undefined;
const SolarContext = createContext<boolean|undefined>(defaultSolarSelection);
const SolarDispatchContext = createContext(null as unknown as React.Dispatch<SolarAction>);

export function SolarContextProvider({children}: { children: React.ReactElement[] }) {
    const [solarSelection, dispatch] = useReducer(
        solarContextReducer,
        defaultSolarSelection
    );

    const calculatorInputDispatch = useCalculatorInputDispatchContext();

    /*
     * This fires after dispatch() is complete so that we can bubble up the context value to CalculatorInputContext
     */
    useEffect(() => {
        calculatorInputDispatch({
            type: "setSolarContextValue",
            value: solarSelection
        });
    }, [solarSelection, calculatorInputDispatch]);

    return (
        <SolarContext.Provider value={solarSelection}>
            <SolarDispatchContext.Provider value={dispatch}>
                {children}
            </SolarDispatchContext.Provider>
        </SolarContext.Provider>
    );
}

export function useSolarContext() {
    return useContext(SolarContext);
}

export function useSolarDispatchContext() {
    return useContext(SolarDispatchContext);
}