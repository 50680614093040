import {useStepDispatchContext} from "contexts/StepContext";
import {useSolarContext} from "contexts/SolarContext";
import React, {useEffect, useId, useState} from "react";
import HeatingType from "types/HeatingType";
import {useCalculatorInputDispatchContext, useCalculatorInputContext} from "contexts/CalculatorInputContext";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import ToggleButtonGroup from "react-bootstrap/ToggleButtonGroup";
import ToggleButton from "react-bootstrap/ToggleButton";
import Form from "react-bootstrap/Form";
import NumericGroup from "components/common/NumericGroup/NumericGroup";
import InputNumeric from "components/common/InputNumeric/InputNumeric";
import NumericGroupSCSS from "components/common/NumericGroup/NumericGroup.module.scss";
import ScreenHeader from "components/common/ScreenHeader/ScreenHeader";
import Col from "react-bootstrap/Col";
import {ListGroup} from "react-bootstrap";
import ContentRow from "components/helpers/Grid/ContentRow";
import getBuildingTypeClass from "components/helpers/GetBuildingTypeClass";
import InfoPopover from "components/common/InfoPopover/InfoPopover";
import SCSSModule from "./Step5.module.scss";
import ancillaryContentDictionary, {TooltipACDT} from "components/common/AncillaryContentModal/AncillaryContent";
import StepNavigation from "components/common/StepNavigation/StepNavigation";
import {scrollToTop, updateHeight} from "utils/iframe";
import GutterInfo from "components/common/InfoPopover/GutterInfo";

export type HeatingSourceInput = {
    spaceHeatingType: HeatingType,
    DHWHeatingType: HeatingType,
    existingElectricConsumption?: number,
    existingGasConsumption?: number,
}

function Step5(): React.ReactElement {
    const stepDispatch = useStepDispatchContext();
    const usingSolar = useSolarContext();
    const calculatorInputDispatch = useCalculatorInputDispatchContext();
    const calculatorInput = useCalculatorInputContext();

    const [spaceHeatingType, setSpaceHeatingType] = useState<HeatingType>(calculatorInput.HeatingSourceInputValue?.spaceHeatingType ?? "gas");
    const [DHWHeatingType, setDHWHeatingType] = useState<HeatingType>(calculatorInput.HeatingSourceInputValue?.DHWHeatingType ?? "gas");
    const [gasConsumption, setGasConsumption] = useState<number | undefined>(calculatorInput.HeatingSourceInputValue?.existingGasConsumption);
    const [electricConsumption, setElectricConsumption] = useState<number | undefined>(calculatorInput.HeatingSourceInputValue?.existingElectricConsumption);

    useEffect(() => {
        updateHeight();
        scrollToTop();
    }, []);

    function goToPrev() {
        if (usingSolar) {
            stepDispatch({type: "goToPrev"});
        } else {
            stepDispatch({type: "goBackward", numSteps: 2});
        }
    }

    function goToNext() {
        calculatorInputDispatch({
            type: "setHeatingSourceInput",
            value: {
                spaceHeatingType: spaceHeatingType,
                DHWHeatingType: DHWHeatingType,
                existingGasConsumption: gasConsumption,
                existingElectricConsumption: electricConsumption
            }
        });
        stepDispatch({type: "goToNext"});
    }

    function onChangeSpaceHeating(value: HeatingType): void {
        setSpaceHeatingType(value || undefined);
    }

    function onChangeDHWHeating(value: HeatingType): void {
        setDHWHeatingType(value || undefined);
    }

    function onChangeGasConsumption(value: number | false): void {
        setGasConsumption(value || undefined);
    }

    function onChangeElectricConsumption(value: number | false): void {
        setElectricConsumption(value || undefined);
    }

    function getBuildingClass() {
        return getBuildingTypeClass(calculatorInput.BuildingInputContextValue!.BuildingTypeId!);
    }
    
    const ACDT = ancillaryContentDictionary.get(5)! as TooltipACDT;
    const tooltips = ACDT.tooltips;

    return <Container className={SCSSModule["step5"]}>
        <ScreenHeader title={"Let’s talk about your building’s systems"} optional={true}/>

        <ContentRow firstMainContentRow={true} rowClassName={SCSSModule["list-group-item-row"]} leftGutter={
            <GutterInfo>
                <InfoPopover title={tooltips.get(1)!.title} content={tooltips.get(1)!.content}/>
            </GutterInfo>
        }>
            <Col className={SCSSModule["custom-padding"]}>
                <ListGroup as="ol" numbered className={getBuildingClass()}>
                    <ListGroup.Item as="li">
                        <Form.Group controlId={useId()}>
                            <Row>
                                <Col className={"ps-md-0"} xl={6} md={12}>
                                    <Form.Label>Select your predominate heating source</Form.Label>
                                </Col>
                                <Col className={"pe-md-0"} xl={6} md={12}>
                                    <ToggleButtonGroup type={"radio"} name={"spaceHeating"}
                                                       defaultValue={spaceHeatingType}
                                                       onChange={onChangeSpaceHeating} className={"toggle-button"}>
                                        <ToggleButton id={useId()} value={"gas"}>Natural gas</ToggleButton>
                                        <ToggleButton id={useId()} value={"electric"}>Electric</ToggleButton>
                                    </ToggleButtonGroup>
                                </Col>
                            </Row>
                        </Form.Group>
                    </ListGroup.Item>
                </ListGroup>
            </Col>
        </ContentRow>

        <ContentRow firstMainContentRow={true} rowClassName={SCSSModule["list-group-item-row"]} leftGutter={
            <GutterInfo>
                <InfoPopover title={tooltips.get(2)!.title} content={tooltips.get(2)!.content}/>
            </GutterInfo>
        }>
            <Col className={SCSSModule["custom-padding"]}>
                <ListGroup as="ol" numbered>
                    <ListGroup.Item as="li" className={"li-two"}>
                        <Form.Group controlId={useId()}>
                            <Row>
                                <Col className={"ps-md-0"} xl={6} md={12}>
                                    <Form.Label>Select your domestic hot water heating source</Form.Label>
                                </Col>
                                <Col className={"pe-md-0"} xl={6} md={12}>
                                    <ToggleButtonGroup type={"radio"} name={"DHWHeating"}
                                                       defaultValue={DHWHeatingType}
                                                       onChange={onChangeDHWHeating} className={"toggle-button"}>
                                        <ToggleButton id={useId()} value={"gas"}>Natural gas</ToggleButton>
                                        <ToggleButton id={useId()} value={"electric"}>Electric</ToggleButton>
                                    </ToggleButtonGroup>
                                </Col>
                            </Row>
                        </Form.Group>
                    </ListGroup.Item>
                </ListGroup>
            </Col>
        </ContentRow>
        <ContentRow firstMainContentRow={true} rowClassName={SCSSModule["list-group-item-row"]} leftGutter={
            <GutterInfo>
                <InfoPopover title={tooltips.get(3)!.title} content={tooltips.get(3)!.content}/>
            </GutterInfo>
        }>
            <Col className={SCSSModule["custom-padding"]}>
                <ListGroup as="ol" numbered>
                    <ListGroup.Item as="li" className={"li-three"}>
                        <Form.Group controlId={useId()}>
                            <Row>
                                <Col className={"ps-md-0"} xl={6} md={12}>
                                    <Form.Label>If available, enter annual utility information for a more customized
                                        evaluation</Form.Label>
                                    <Form.Text>We’ll need information about all your building energy sources
                                        to analyze your current building. Don’t have all the data? Fear not!
                                        We’ll use a typical baseline to build your model.</Form.Text>
                                </Col>
                                <Col className={"pe-md-0"} xl={6} md={12}>
                                    <NumericGroup controlId={useId()} className={`numeric-group ${NumericGroupSCSS["small-numeric-group"]}`}>
                                        <Row>
                                            <Col>
                                                <Form.Label>Natural gas</Form.Label>
                                                <InputNumeric suffix={" therms"} defaultValue={gasConsumption}
                                                              onNumberChange={onChangeGasConsumption}
                                                              placeholder={"Enter usage in therms"}
                                                              className={NumericGroupSCSS["small-input"]}/>
                                            </Col>
                                        </Row>
                                    </NumericGroup>
                                    <NumericGroup controlId={useId()} className={`numeric-group ${NumericGroupSCSS["small-numeric-group"]}`}>
                                        <Row>
                                            <Col>
                                                <Form.Label>Electric</Form.Label>
                                                <InputNumeric suffix={" kWh"}
                                                              defaultValue={electricConsumption}
                                                              onNumberChange={onChangeElectricConsumption}
                                                              placeholder={"Enter usage in kWh"}
                                                              className={NumericGroupSCSS["small-input"]}/>
                                            </Col>
                                        </Row>
                                    </NumericGroup>
                                </Col>
                            </Row>
                        </Form.Group>
                    </ListGroup.Item>
                </ListGroup>
            </Col>
        </ContentRow>

        <StepNavigation goToPrev={goToPrev} goToNext={goToNext}/>
    </Container>;
}

export default Step5;