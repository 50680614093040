import {StepValue} from "contexts/StepContext";
import {
    BuildingType,
    commercialOfficeBuilding,
    educationalFacility,
    midriseBuildingIncludingARestaurant,
    midriseBuildingWithoutARestaurant,
    multifamilyHighRise
} from "etc/BuildingTypes";

export type OrderedTooltipMap = Map<number, {title?:string, content:string}>;
export type EducationalContentMap = Map<number|BuildingType, {title?:string, content:string}>;

export enum ACDTTypes {
    tooltip = "tooltip",
    content = "content",
    combination = "combination"
}
export type TooltipACDT = {type: ACDTTypes.tooltip, tooltips: OrderedTooltipMap};
export type ContentACDT = {type: ACDTTypes.content, content: EducationalContentMap};
export type CombinationACDT = {type: ACDTTypes.combination, tooltips: OrderedTooltipMap, content: EducationalContentMap};

export type ACDD = StepValue;
export type ACDT = TooltipACDT | ContentACDT | CombinationACDT;
export type ACD = Map<ACDD, ACDT>;

const ancillaryContentDictionary:ACD = new Map([
    [1, {
        type: ACDTTypes.tooltip,
        tooltips: new Map([
            [1, {
                content: "Select the building option that most closely matches your project"
            }]
        ])
    }],
    [2, {
        type: ACDTTypes.content,
        content: new Map([
            [commercialOfficeBuilding, {title: "Not sure?", content: "Here is a common size for your building type: 50,000 sq. ft."}],
            [educationalFacility, {title: "Not sure?", content: "Here is a common size for your building type: 200,000 sq. ft."}],
            [multifamilyHighRise, {title: "Not sure?", content: "Here is a common size for your building type: 150,000 sq. ft."}],
            [midriseBuildingWithoutARestaurant, {title: "Not sure?", content: "Here is a common size for your building type: 50,000 sq. ft."}],
            [midriseBuildingIncludingARestaurant, {title: "Not sure?", content: "Here is a common size for your building type: 50,000 sq. ft."}],
        ])
    }],
    [4, {
        type: ACDTTypes.content,
        content: new Map([
            [1, {
                title: "Increase your impact",
                content: "To get the most benefit from your solar, consider pairing it with storage. In addition to options for resiliency, partnering solar with storage will further reduce your operating emissions."
            }],
        ])
    }],
    [5, {
        type: ACDTTypes.tooltip,
        tooltips: new Map([
            [1, {
                title: "Heating energy source",
                content: "Select the heating source for your new or existing building. For example, boilers are often natural gas whereas heat pumps are electric."
            }],
            [2, {
                title: "Hot water heating source",
                content: "Select the hot water energy source for your new or existing building. For example, boilers are often natural gas whereas heat pumps and resistance are electric."
            }],
            [3, {
                title: "Utility data",
                content: "Enter the annual energy use for your sources from your utility bill (excluding PV). If your project has natural gas and electricity, data for both sources is needed for the customized model."
            }],
        ])
    }],
    [6, {
        type: ACDTTypes.tooltip,
        tooltips: new Map([
            [1, {
                title: "What is flexible load?",
                content: "Flexible load is the ability to adjust a building's energy use to help optimize grid operations and earn some incentives. Buildings are key partners in creating a clean, reliable electric system."
            }]
        ])
    }]
]);

export default ancillaryContentDictionary;
