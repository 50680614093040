import pge from "./pge";

const baseURL = pge.isProd ? "https://pgn4.my.salesforce.com/services/" : "https://pgn4--co.sandbox.my.salesforce.com/services/";

export type BodyInput = {[prop: string]:string|number};
export type Body = {[prop: string]:string};
export enum LeadPath {
    "OauthToken",
    "CreateLead",
    "PatchLead"
}

export type Request = {
    path:LeadPath,
    id?: string,
    body:BodyInput,
    headers:HeadersInit
};

export const Fetch = async ({path, id, body, headers}:Request): Promise<object> => {
    const requestHeaders = new Headers(headers);

    let truePath:string;
    switch (path) {
        case LeadPath.OauthToken:
            truePath = baseURL+"oauth2/token";
            break;
        case LeadPath.CreateLead:
            truePath = baseURL+"data/v58.0/sobjects/Lead";
            break;
        case LeadPath.PatchLead:
            truePath = baseURL+"data/v58.0/sobjects/Lead";
            break;
    }

    if (id) {
        if (path === LeadPath.PatchLead) {
            truePath += `/${id}/`;
        } else {
            throw new Error(`POST requests do not support a Lead ID.`);
        }
    }

    const method = path === LeadPath.PatchLead ? "PATCH" : "POST";
    const request = {
        method: method,
        headers: requestHeaders,
        body: requestHeaders.get("Content-Type") === "application/x-www-form-urlencoded"
            ? new URLSearchParams(
                Object.fromEntries(Object.entries(body).map( ([k,v]) => [k, String(v)] )) as Body
            )
            : JSON.stringify(body)
    };

    const response = await fetch(truePath, request);

    console.log({
        request: request,
        response: response
    });

    return method === "POST" ? await response.json() : await response.text();
};