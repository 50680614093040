import React, {useEffect} from "react";
import {useStepDispatchContext} from "contexts/StepContext";
import {useSolarDispatchContext} from "contexts/SolarContext";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import ScreenHeader from "components/common/ScreenHeader/ScreenHeader";
import Col from "react-bootstrap/Col";
import ContentRow from "components/helpers/Grid/ContentRow";
import {Card} from "react-bootstrap";
import iconYesSolar from './images/solar_yes.svg';
import iconNoSolar from './images/solar_no.svg';
import {useCalculatorInputContext} from "contexts/CalculatorInputContext";
import StepNavigation from "components/common/StepNavigation/StepNavigation";
import SCSSModule from "../Step3/Step3.module.scss";
import {scrollToTop, updateHeight} from "utils/iframe";

function Step3(): React.ReactElement {
    const stepDispatch = useStepDispatchContext();
    const solarDispatch = useSolarDispatchContext();
    const calculatorInput = useCalculatorInputContext();

    useEffect(() => {
        updateHeight();
        scrollToTop();
    }, []);

    function goToPrev() {
        stepDispatch({type: "goToPrev"});
    }

    function goToSolarStep() {
        solarDispatch({type: "useSolar"});
        stepDispatch({type: "goToNext"});
    }

    function skipSolarStep() {
        solarDispatch({type: "dontUseSolar"});
        stepDispatch({type: "goForward", numSteps: 2});
    }

    function solarSelected() {
        return calculatorInput.SolarContextValue;
    }

    return <Container className={SCSSModule["step3"]}>
        <ScreenHeader title={"Are you considering on-site solar?"}/>

        <ContentRow firstMainContentRow={true}>
            <Col xs={12} md={8} xl={5} className={"px-md-0"}>
                <Form>
                    <div className={"selection-card"}>
                        <Card onClick={goToSolarStep} className={solarSelected() === true ? "active" : ""}>
                            <Card.Img variant="left" src={iconYesSolar} width={35} height={35}/>
                            <Card.Body>
                                <Card.Title>Yes</Card.Title>
                                <Card.Text>
                                    Fantastic! Let’s get some more information.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                        <Card onClick={skipSolarStep} className={solarSelected() === false ? "active" : ""}>
                            <Card.Img variant="left" src={iconNoSolar} width={35} height={35}/>
                            <Card.Body>
                                <Card.Title>No</Card.Title>
                                <Card.Text>
                                    That's okay! We’re here if you change your mind.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>
                </Form>
            </Col>
            <Col xl={3} className={`pe-md-0 ${SCSSModule["step3-solar"]}`}/>
        </ContentRow>

        <StepNavigation goToPrev={goToPrev} nextAvailable={false}/>
    </Container>;
}

export default Step3;