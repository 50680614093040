import React from "react";
import SCSSModule from "./ScreenHeader.module.scss";
import Col from "react-bootstrap/Col";
import ContentRow from "components/helpers/Grid/ContentRow";

function ScreenHeader({title, subtitle, optional, resultsPage=false}: ScreenHeaderProps): React.ReactElement {

    return <ContentRow resultsPage={resultsPage} rowClassName={SCSSModule["screen-header-row"]}>
        <Col md={8} xl={5} className={"ps-md-0"}>
            <div className={SCSSModule["screen-header"]}>
                {optional && <p className={SCSSModule["optional"]}>Optional</p>}
                <h3 className={SCSSModule["title"]}>{title}</h3>
                {subtitle && <p className={SCSSModule["subtitle"]}>{subtitle}</p>}
            </div>
        </Col>
        <Col className={"d-none d-xl-block pe-md-0"} xl={3} />
    </ContentRow>;
}

export default ScreenHeader;