import React from "react";
import SCSSModule from "./LearnMore.module.scss";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function LearnMore(): React.ReactElement {
    return (
        <Container className={SCSSModule["learn-more"]}>
            <Row>
                <Col xl={6} md={6}>
                    <span className={SCSSModule["title"]}>Learn more about our programs to help you save money and increase your impact</span>
                </Col>
                <Col xl={6} md={6} className={SCSSModule["lightbulb-col"]} />
            </Row>
            <Row className={SCSSModule["links"]}>
                <Col xl={6} md={6}>
                    <a href="https://portlandgeneral.com/save-money/save-money-business/energy-partner-program" target="_blank" rel="noreferrer">Energy Partner On Demand</a>
                    <a href="https://portlandgeneral.com/save-money/save-money-business/dispatchable-standby-generators" target="_blank" rel="noreferrer">Energy Partner Resiliency</a>
                    <a href="https://portlandgeneral.com/save-money/save-money-business/energy-partner-smart-thermostat" target="_blank" rel="noreferrer">Energy Partner Smart Thermostat</a>
                </Col>
                <Col xl={6} md={6}>
                    <a href="https://portlandgeneral.com/energy-choices/renewable-power/green-future-enterprise" target="_blank" rel="noreferrer">Green Future Enterprise</a>
                    <a href="https://portlandgeneral.com/energy-choices/generate-power/get-started-solar" target="_blank" rel="noreferrer">Solar panel costs, incentives and benefits</a>
                    <a href="https://portlandgeneral.com/about/who-we-are/resource-planning/combined-cep-and-irp" target="_blank" rel="noreferrer">PGE’s Clean Energy Plan</a>
                </Col>
            </Row>
        </Container>
    );
}

export default LearnMore;