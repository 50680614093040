import {
    VictoryLine,
    VictoryArea,
    VictoryChart,
    VictoryGroup,
    VictoryAxis,
    VictoryLabel,
    VictoryTooltip,
    VictoryVoronoiContainer,
    VictoryScatter
} from "victory";
import React, {useState, useRef} from "react";
import {YearlyValuesResult} from "types/InputProcessorType";
import GraphSCSS from "./Graph.module.scss";
import colors from "styles/useables/_Colors.scss";
import fonts from "styles/useables/_Fonts.scss";
import Button from "react-bootstrap/Button";
import GraphFontMath from "components/helpers/GraphFontMath";

function Graph({resultMap, cta, onClick}:YearlyOperatingEmissionsProps): React.ReactElement {
    let [lineActivePoint, setLineActivePoint] = useState<Dataset>([]);
    let [areaActivePoint, setAreaActivePoint] = useState<Dataset>([]);
    const refSVG = useRef<null | HTMLDivElement>(null);
    function yearlyValuesResultToDataset(datum: keyof YearlyValuesResult, show: boolean = true) {
        return Array.from(resultMap.entries()).flatMap(([year, values]: [year: number, values: YearlyValuesResult]): Dataset => [{x: year, y: Math.floor(values[datum] ?? 0), show}]);
    }

    const referenceVectors: Dataset = yearlyValuesResultToDataset("referenceGHG");
    const improvedVectors: Dataset = yearlyValuesResultToDataset("improvedGHGwSolar", false);
    const highestY:number = referenceVectors[0].y;

    let upperYTick = Math.floor((highestY*0.9)/100)*100;
    let lowerYTick = Math.ceil((highestY*0.5)/100)*100;
    if (upperYTick === lowerYTick) {
        upperYTick += 50;
        lowerYTick = (lowerYTick > 50) ? lowerYTick-50 : lowerYTick;
    }

    const keys = [...resultMap.keys()];
    const firstYear = keys[0];
    const yearFifteen = keys[keys.length - 6];
    const lastYear = keys[keys.length - 1];

    const {
        graphFontSize
    } = GraphFontMath({
        refSVG
    });

    const axisBaseStyle = {
        axis: {
            stroke: colors["starlight-blue"], strokeWidth: 2, fontSize: graphFontSize, fontFamily: fonts["font-family"]
        }, axisLabel: {
            fill: colors["starlight-blue"], textAnchor: "end", fontWeight: 700, x: "0%", fontSize: graphFontSize, fontFamily: fonts["font-family"]
        }, tickLabels: {
            fill: colors["starlight-blue"], fontWeight: 700, fontSize: graphFontSize, fontFamily: fonts["font-family"]
        },
    };

    let activePointTimer: ReturnType<typeof setTimeout>;

    function activatePoint(point: any = []) {
        activePointTimer = setTimeout(() => {
            clearTimeout(activePointTimer);
            setLineActivePoint([{x: point[0].x, y: point[0].y}]);
            setAreaActivePoint([{x: point[1].x, y: point[1].y}]);
        }, 100);
    }

    return <>
        <div className={GraphSCSS['yearly-emissions-title']}>
            <h4>Emissions impact of selected features</h4>
            <Button variant="primary" onClick={onClick}>{cta}</Button>
        </div>
        <div className={GraphSCSS['graph-help']}>
            <p className={GraphSCSS['natural-gas']}>Baseline scenario</p>
            <p className={GraphSCSS['electrification']}>Your improved scenario</p>
        </div>

        <div className={GraphSCSS["yearly-emissions"]} ref={refSVG}>
            <VictoryChart minDomain={{y: 0}}
                width={graphFontSize === 14 ? 450 : 750}
                containerComponent={<VictoryVoronoiContainer
                    onActivated={activatePoint}
                    voronoiDimension="x"
                    mouseFollowTooltips
                    voronoiBlacklist={["activepoints"]}
                    labels={({datum}) => {
                        const blankLink = " \n ";
                        let labelToRender: any;
                        if (!datum.childName.includes("scatter")) {
                            if (datum.show) {
                                // First line
                                labelToRender = `${datum.x + "\n"}${blankLink}Baseline scenario:\n${Math.round(datum.y)} MTCO2e`;
                            } else {
                                // Second line
                                labelToRender = `${blankLink}Your improved scenario:\n${Math.round(datum.y)} MTCO2e`;
                            }
                        }
                        return labelToRender;
                    }}
                    labelComponent={<VictoryTooltip
                        center={{ x: graphFontSize === 14 ? 320 : 580, y: 70 }}
                        constrainToVisibleArea
                        cornerRadius={6}
                        flyoutWidth={graphFontSize === 14 ? 180 : 150}
                        flyoutHeight={110}
                        flyoutStyle={{
                            stroke: "none", fill: colors["light-gray-2"], outline: `${colors["light-gray-2"]} solid 10px`
                        }}
                        labelComponent={<VictoryLabel
                            style={[{
                                fill: colors["starlight-blue"], fontFamily: fonts["font-family"],
                                fontWeight: 600, fontSize: graphFontSize
                            }]}
                            dx={graphFontSize === 14 ? -78 : -70}
                            dy={5}
                            textAnchor="start"
                        />}
                    />}
                />}>
                {/* Y axis */}
                <VictoryAxis
                    dependentAxis
                    label="MTCO2e"
                    style={axisBaseStyle}
                    axisLabelComponent={<VictoryLabel dx={-39} dy={15}/>}
                    tickValues={[upperYTick, lowerYTick]}
                />

                {/* X axis */}
                <VictoryAxis
                    offsetY={49}
                    axisLabelComponent={<VictoryLabel dx={-141} dy={-14}/>}
                    label="Year"
                    tickValues={[firstYear, yearFifteen, lastYear]}
                    style={axisBaseStyle}
                />

                <VictoryGroup
                    style={{
                        data: {strokeWidth: 1, fillOpacity: 0.2}
                    }}
                >
                    <VictoryLine
                        style={{
                            data: {
                                stroke: colors["nw-salmon"], strokeDasharray: 2
                            }
                        }}
                        data={referenceVectors}
                    />

                    <VictoryArea
                        style={{
                            data: {
                                fill: colors["elec-yellow"], stroke: colors["gold"], fillOpacity: 0.8
                            }
                        }}
                        data={improvedVectors}
                    />
                </VictoryGroup>


                {lineActivePoint.length > 0 ? (<VictoryScatter
                    size={5}
                    style={{
                        data: {
                            fill: colors["nw-salmon"], fillOpacity: 1
                        }
                    }}
                    data={lineActivePoint}
                />) : null}

                {areaActivePoint.length > 0 ? (<VictoryScatter
                    size={5}
                    style={{
                        data: {
                            fill: colors["gold"], fillOpacity: 1
                        }
                    }}
                    data={areaActivePoint}
                />) : null}
            </VictoryChart>
        </div>
    </>;
}

export default Graph;