import React, {ReactNode, useId} from "react";
import Form from 'react-bootstrap/Form';
import {FormGroupProps} from "react-bootstrap/FormGroup";
import SCSSModule from "./NumericGroup.module.scss";
import {addClassName} from "utils/general";

function NumericGroup({
    controlId,
    children,
    ...rest
}: FormGroupProps): React.ReactElement {
    const backupId = useId();

    rest.className = addClassName(rest.className, SCSSModule.self);

    return <Form.Group controlId={controlId || backupId} {...rest}>
        <div className={SCSSModule.container}>
            {/* @ts-ignore */}
            {React.Children.toArray(children).filter((child:ReactNode) => child?.type?.displayName !== "FormText")}
        </div>

        {/* @ts-ignore */}
        {React.Children.toArray(children).filter((child:ReactNode) => child?.type?.displayName === "FormText")}
    </Form.Group>;
}

export default NumericGroup;
