import React, {useEffect, useId, useReducer} from "react";
import Form from 'react-bootstrap/Form';
import SCSSModule from "./CheckboxGroup.module.scss";
import {ERMId, ERMs} from "types/ERMType";

function CheckboxGroupComponent<T extends CheckboxKey>({checkboxes, selectAll, onChange, setSelectionText, defaultSelectionText}: CheckboxGroupProps<T>): React.ReactElement {
    let initialCheckedValues: CheckedValuesMap<T> = new Map();
    const checkboxGroupId = useId();

    checkboxes.forEach((checkboxProps, value) => {
        initialCheckedValues = CheckboxGroupReducer<T>(
            initialCheckedValues,
            {
                type: "updateValue",
                value: value,
                checked: checkboxProps.checked ?? false
            }
        );
    });

    const [checkedValues, dispatchCheckedValueChange] = useReducer(CheckboxGroupReducer<T>, initialCheckedValues);

    function numericStringToNumber(input:string|number): string|number {
        return (Number(input).toString() === input) ? Number(input) : input;
    }

    function onChangeCheckbox(event: React.ChangeEvent<HTMLInputElement>): void {
        dispatchCheckedValueChange({
            type: "updateValue",
            value: numericStringToNumber(event.target.value) as T,
            checked: event.target.checked
        });
    }

    function onChangeSelectAll(event: React.ChangeEvent<HTMLInputElement>): void {
        dispatchCheckedValueChange({
            type: event.target.checked ? "selectAll" : "unselectAll"
        });
    }

    function isAllSelected(): boolean {
        let allSelected = true;
        for (const checked of checkedValues.values()) {
            if (checked === false) {
                allSelected = false;
                break;
            }
        }
        return allSelected;
    }

    useEffect(() => {
        onChange(checkedValues);

        const selectedValues = Array.from(checkedValues.entries()).filter((entry) => entry[1] === true);
        const selected = selectedValues.length;

        if (selected === 0) {
            setSelectionText(defaultSelectionText);
        } else if (selected === 1) {
            // @ts-ignore
            let selectedId:ERMId = Object.values(selectedValues[0])[0];
            setSelectionText(String(ERMs.get(selectedId)?.label));
        } else if (selected > 0) {
            setSelectionText(selected + " selected");
        }
    }, [checkedValues, defaultSelectionText, setSelectionText, onChange]);

    return <>
        {selectAll && (
            <div className={SCSSModule.checkbox}>
                <Form.Check>
                    <Form.Check.Input onChange={onChangeSelectAll} value={"select-all"}
                                      checked={isAllSelected()} id={checkboxGroupId + "select-all"} />
                    <Form.Check.Label htmlFor={checkboxGroupId + "select-all"}>
                        <span className={"checkbox-description"}>{"Select all"}</span>
                    </Form.Check.Label>
                </Form.Check>
            </div>
        )}

        {Array.from(checkboxes).map(([value, checkbox]) =>
            <div key={value} className={SCSSModule.checkbox}>
                <Form.Check>
                    <Form.Check.Input onChange={onChangeCheckbox} value={value}
                                      checked={checkedValues.get(value)} id={checkboxGroupId + value} />
                    <Form.Check.Label htmlFor={checkboxGroupId + value}>
                        <span className={"checkbox-label"}>{checkbox.label}</span>
                        <span className={"checkbox-description"}>{checkbox.description}</span>
                    </Form.Check.Label>
                </Form.Check>
            </div>
        )}
    </>;
}

const ERMCheckboxGroup:CheckboxGroupComponent<ERMId> = CheckboxGroupComponent;
const StringCheckboxGroup:CheckboxGroupComponent<string> = CheckboxGroupComponent;
const NumberCheckboxGroup:CheckboxGroupComponent<number> = CheckboxGroupComponent;

export function CheckboxGroupReducer<T>(checkedValues: CheckedValuesMap<T>, action: CheckboxGroupReducerAction<T>) {
    let resultMap: CheckedValuesMap<T> = new Map(checkedValues);
    switch (action.type) {
        case "updateValue":
            resultMap.set(action.value, action.checked);
            break;
        case "selectAll":
            for (const value of checkedValues.keys()) {
                resultMap.set(value, true);
            }
            break;
        case "unselectAll":
            for (const value of checkedValues.keys()) {
                resultMap.set(value, false);
            }
            break;
        case "toggleValue":
            resultMap.set(action.value, !checkedValues.get(action.value));
            break;
        case "toggleAll":
            for (const [value, checked] of checkedValues.entries()) {
                resultMap.set(value, !checked);
            }
            break;
    }
    return resultMap;
}

// export default ERMCheckboxGroup as CheckboxGroup;
export {ERMCheckboxGroup, StringCheckboxGroup, NumberCheckboxGroup};