import React, {createContext, SetStateAction, useContext, useState} from "react";
import {InputProcessorResult} from "types/InputProcessorType";

type ResultsContextValue = undefined | InputProcessorResult;
type ResultsContextDispatch = React.Dispatch<SetStateAction<ResultsContextValue>>;

const ResultsContext = createContext<ResultsContextValue>(undefined);
const ResultsDispatchContext = createContext(undefined as unknown as ResultsContextDispatch);

export function ResultsContextProvider({children}: { children: React.ReactElement | React.ReactElement[] }) {
    const [results, setResults] = useState<ResultsContextValue>();

    return (
        <ResultsContext.Provider value={results}>
            <ResultsDispatchContext.Provider value={setResults}>
                {children}
            </ResultsDispatchContext.Provider>
        </ResultsContext.Provider>
    );
}

export function useResultsContext() {
    return useContext(ResultsContext);
}

export function useResultsDispatchContext() {
    return useContext(ResultsDispatchContext);
}