import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import {ReactComponent as Icon} from "./images/icon.svg";
import SCSSModule from "./FlexibleLoad.module.scss";
import {SumResult, YearOneResult} from "types/InputProcessorType";

type FlexibleLoadTab = {
    eventKey: string, title: string, baseCost: number, cost: number, savings: number
};

function FlexibleLoad({yearOne, sums}: {
    yearOne: YearOneResult,
    sums: SumResult
}): React.ReactElement {
    const tabData: FlexibleLoadTab[] = [{
        eventKey: "one",
        title: "1 year",
        baseCost: yearOne.year1ReferenceCost,
        cost: yearOne.year1NetCost,
        savings: Math.floor(yearOne.year1ReferenceCost - yearOne.year1NetCost),
    }, {
        eventKey: "twenty",
        title: "20 years",
        baseCost: sums.totalReferenceCost,
        cost: sums.totalNetCost,
        savings: Math.floor(sums.totalReferenceCost - sums.totalNetCost),
    }];

    return <Row className={SCSSModule["flexible-load"]}>
        <Col className={"col-12 ps-md-0"} md={4}>
            <div className={SCSSModule["card"]}>
                <Icon/>
                <h3 className={SCSSModule["title"]}>Why flexible loads?</h3>
                <p className={SCSSModule["description"]}>
                    Grid-interactive buildings are one of the most impactful tools available. Connected systems or
                    batteries help us optimize renewable generation. PGE has multiple flexible load incentives to
                    help you reduce upfront costs and save long term.
                </p>
            </div>
        </Col>
        <Col className={"col-12 pe-md-0"} md={8}>
            <div className={SCSSModule["tabs"]}>
                <Tabs defaultActiveKey="one" variant="underline">
                    {tabData.map((tabDatum, index) => <Tab key={index} eventKey={tabDatum.eventKey}
                                                           title={tabDatum.title}>
                        <h3 className={SCSSModule["title"]}>High value from high performance</h3>
                        <Row>
                            <Col className={"col-12"} xl={7}>Baseline scenario operating cost</Col>
                            <Col className={"col-12"} xl={5}>${Math.floor(tabDatum.baseCost).toLocaleString()}</Col>
                        </Row>
                        <Row>
                            <Col>&nbsp;</Col>
                        </Row>
                        <Row>
                            <Col className={"col-12"} xl={7}>Your improved building scenario operating cost</Col>
                            <Col className={"col-12"} xl={5}>${Math.floor(tabDatum.cost).toLocaleString()}</Col>
                        </Row>
                        <Row>
                            <Col>&mdash;&mdash;&mdash;&mdash;<br/>&nbsp;</Col>
                        </Row>
                        <Row>
                            <Col className={"col-12"} xl={7}>
                                {tabDatum.savings < 0 ? "Your investment in sustainability" : "Your efficiency savings"} ${Math.abs(Math.floor(tabDatum.savings)).toLocaleString()}
                            </Col>
                            <Col className={"col-12"} xl={5}/>
                        </Row>
                    </Tab>)}
                </Tabs>
            </div>
        </Col>
    </Row>;
}

export default FlexibleLoad;
