import React from "react";
import SCSSModule from "./GutterInfo.module.scss";

function GutterInfo({children}: { children: React.ReactElement | React.ReactElement[] }): React.ReactElement {
    return (
        <div className={SCSSModule["gutter-info"]}>
            {children}
        </div>
    );
}

export default GutterInfo;
