import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import SCSSModule from "./ContentRow.module.scss";
import {addClassName} from "utils/general";

type ContentRowProps = React.PropsWithChildren & {
    children:React.ReactElement | React.ReactElement[],
    resultsPage?: boolean,
    leftGutter?: React.ReactNode | undefined
    rightGutter?: React.ReactNode | undefined
    rowClassName?: string,
    firstMainContentRow?: boolean
};

function ContentRow({resultsPage=false, leftGutter, children, rightGutter, rowClassName, firstMainContentRow=false}:ContentRowProps): React.ReactElement {
    const childArray = Array.isArray(children) ? children : [children];

    childArray.forEach((child, _index) => {
        // @ts-ignore
        if (child.type.displayName !== "Col") {
            throw new Error("ContentRow may only contain children of type Col");
        }
    });

    rowClassName = rowClassName ?? "";
    if (resultsPage) {
        rowClassName = addClassName(rowClassName, SCSSModule["ContentRow-result"]);
    }
    if (firstMainContentRow) {
        rowClassName = addClassName(rowClassName, SCSSModule["ContentRow-main-content"]);
    }

    return <Row className={rowClassName ?? ""}>
        {/*
            Desktop - XL:
                Design cols:    12
                Code cols:      12
                Code col ratio: 1

            Tablet - MD:
                Design cols:    6
                Code cols:      12
                Code col ratio: 2

            Mobile - SM:
                Design cols:    4
                Code cols:      12
                Code col ratio: 3
        */}

        {resultsPage ? (
            <Col xl={1} className={`d-none d-xl-block ${SCSSModule["ContentRow-gutter"]} ${SCSSModule["ContentRow-left-gutter"]}`}>
                {leftGutter}
            </Col>
        ) : (
            <Col md={2} xl={2} className={`d-none d-md-block ${SCSSModule["ContentRow-gutter"]} ${SCSSModule["ContentRow-left-gutter"]}`}>
                {leftGutter}
            </Col>
        )}

        {children}

        {resultsPage ? (
            <Col xl={1} className={`d-none d-xl-block ${SCSSModule["ContentRow-gutter"]} ${SCSSModule["ContentRow-right-gutter"]}`}>
                {rightGutter}
            </Col>
        ) : (
            <Col md={2} xl={2} className={`d-none d-md-block ${SCSSModule["ContentRow-gutter"]} ${SCSSModule["ContentRow-right-gutter"]}`}>
                {rightGutter}
            </Col>
        )}
    </Row>;
}

export default ContentRow;
