import React, {useId} from "react";
import {OverlayTrigger, Popover} from "react-bootstrap";
import ButtonOutlineIcon from "../ButtonOutlineIcon/ButtonOutlineIcon";
import SCSSModule from "./InfoPopover.module.scss";


function InfoPopover({title, content, placement}: InfoPopoverProps): React.ReactElement {
    const infoPopoverId = useId();
    const popover = (
        <Popover id={infoPopoverId}>
            <Popover.Header as="h3">{title}</Popover.Header>
            <Popover.Body>
                {content}
            </Popover.Body>
        </Popover>
    );

    return (
        <OverlayTrigger trigger="click" placement={placement || "top-start"} overlay={popover} rootClose={true}>
            <ButtonOutlineIcon symbol={"info"} title={title ?? "Learn more"} className={SCSSModule["info-popover"]} />
        </OverlayTrigger>
    );
}

export default InfoPopover;
