import React, {createContext, useContext, useEffect, useReducer} from "react";
import ResultsParamHandler from "./CalculatorInputUtils";
import {buildingTypes} from "etc/BuildingTypes";
import {InputProcessorType} from "types/InputProcessorType";
import HeatingType from "types/HeatingType";

function calculatorInputContextReducer(currentCalculatorInput: CalculatorInput, action: CalculatorInputAction): CalculatorInput {
    let newCalculatorInput: CalculatorInput = {
        ...currentCalculatorInput
    };

    switch (action.type) {
        case "setBuildingInputContextValue":
            newCalculatorInput.BuildingInputContextValue = action.value;
            break;
        case "setSolarContextValue":
            newCalculatorInput.SolarContextValue = action.value;
            break;
        case "setRoofAreaNumFloors":
            newCalculatorInput.RoofAreaNumFloors = action.value;
            break;
        case "setHeatingSourceInput":
            newCalculatorInput.HeatingSourceInputValue = action.value;
            break;
        case "setFlexibleLoadValue":
            newCalculatorInput.FlexibleLoadValue = action.value;
            break;
        case "setERMsInput":
            newCalculatorInput.ERMsInputValue = action.value;
            break;
        default:
            throw new Error(`Invalid action type ${action["type"]}`);
    }

    newCalculatorInput.minimumInputReached = !!(
        newCalculatorInput.BuildingInputContextValue?.BuildingTypeId !== undefined
        && newCalculatorInput.HeatingSourceInputValue?.spaceHeatingType
        && newCalculatorInput.HeatingSourceInputValue?.DHWHeatingType
        && newCalculatorInput.ERMsInputValue
    );

    return newCalculatorInput;
}

const defaultCalculatorInput: CalculatorInput = {
    minimumInputReached: false
};
const CalculatorInputContext = createContext<CalculatorInput>(defaultCalculatorInput);
const CalculatorInputDispatchContext = createContext(null as unknown as React.Dispatch<CalculatorInputAction>);

export function CalculatorInputContextProvider({children}: { children: React.ReactElement | React.ReactElement[] }) {
    const [CalculatorInput, dispatch] = useReducer(
        calculatorInputContextReducer,
        defaultCalculatorInput
    );

    useEffect(() => {
        ResultsParamHandler.init(dispatch);
    }, [dispatch]);

    return (
        <CalculatorInputContext.Provider value={CalculatorInput}>
            <CalculatorInputDispatchContext.Provider value={dispatch}>
                {children}
            </CalculatorInputDispatchContext.Provider>
        </CalculatorInputContext.Provider>
    );
}

export function useCalculatorInputContext() {
    return useContext(CalculatorInputContext);
}

export function useCalculatorInputDispatchContext() {
    return useContext(CalculatorInputDispatchContext);
}

export function getBuildingArea(calculatorInput: CalculatorInput): {
    buildingArea: number,
    isBuildingAreaDefault: boolean
} {
    if (calculatorInput.BuildingInputContextValue?.BuildingArea) {
        return {
            buildingArea: calculatorInput.BuildingInputContextValue.BuildingArea,
            isBuildingAreaDefault: false
        };
    } else if (calculatorInput.BuildingInputContextValue?.BuildingTypeId !== undefined) {
        const buildingArea = buildingTypes.get(calculatorInput.BuildingInputContextValue.BuildingTypeId)?.defaultArea;
        if (!buildingArea) {
            throw new Error(`defaultArea not found for BuildingTypeId ${calculatorInput.BuildingInputContextValue.BuildingTypeId}`);
        }
        return {
            buildingArea: buildingArea,
            isBuildingAreaDefault: true
        };
    } else {
        throw new Error(`BuildingTypeId and BuildingArea are not set`);
    }
}

export function getImportProcessorData(calculatorInput: CalculatorInput): InputProcessorType {
    if (calculatorInput.BuildingInputContextValue?.BuildingTypeId !== undefined
    && calculatorInput.HeatingSourceInputValue
    && calculatorInput.ERMsInputValue) {
        const buildingTypeId = calculatorInput.BuildingInputContextValue.BuildingTypeId;
        const buildingArea = calculatorInput.BuildingInputContextValue?.BuildingArea;
        const roofArea = calculatorInput.RoofAreaNumFloors?.roofArea;
        const numFloors = calculatorInput.RoofAreaNumFloors?.numFloors;
        const spaceHeatingType = calculatorInput.HeatingSourceInputValue.spaceHeatingType as HeatingType;
        const DHWHeatingType = calculatorInput.HeatingSourceInputValue.DHWHeatingType as HeatingType;
        const existingElectricConsumption = calculatorInput.HeatingSourceInputValue.existingElectricConsumption;
        const existingGasConsumption = calculatorInput.HeatingSourceInputValue.existingGasConsumption;
        const ERMSelections = calculatorInput.ERMsInputValue;
        const flexibleLoad = calculatorInput.FlexibleLoadValue ?? false;
        const unitCount = calculatorInput.BuildingInputContextValue.UnitCount ?? undefined;

        return {
            buildingTypeId: buildingTypeId,
            buildingArea: buildingArea,
            ...(roofArea && {roofArea: roofArea}),
            ...(numFloors && {buildingStories: numFloors}),
            spaceHeatingType: spaceHeatingType,
            DHWHeatingType: DHWHeatingType,
            ...(existingElectricConsumption && {existingElectricConsumption: existingElectricConsumption}),
            ...(existingGasConsumption && {existingGasConsumption: existingGasConsumption}),
            ERMSelections: ERMSelections,
            flexibleLoad: flexibleLoad,
            ...(unitCount && {unitCount: unitCount}),
        };
    }

    throw new Error(`calculatorInput is not ready for getImportProcessorData`);
}