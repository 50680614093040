import React, {useEffect, useId, useState} from "react";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import NumericGroup from "components/common/NumericGroup/NumericGroup";
import {useStepDispatchContext} from "contexts/StepContext";
import {
    getBuildingArea,
    useCalculatorInputContext,
    useCalculatorInputDispatchContext
} from "contexts/CalculatorInputContext";
import InputNumeric from "components/common/InputNumeric/InputNumeric";
import ScreenHeader from "components/common/ScreenHeader/ScreenHeader";
import Col from "react-bootstrap/Col";
import ContentRow from "components/helpers/Grid/ContentRow";
import StepNavigation from "components/common/StepNavigation/StepNavigation";
import EducationalPanel from "components/common/EducationalPanel/EducationalPanel";
import ancillaryContentDictionary, {ContentACDT} from "components/common/AncillaryContentModal/AncillaryContent";
import SCSSModule from "../Step4/Step4.module.scss";
import {scrollToTop, updateHeight} from "utils/iframe";

export type RoofAreaNumFloorsInput = {
    roofArea: number,
    numFloors: never
} | {
    roofArea: never,
    numFloors: number
};

function Step4(): React.ReactElement {
    const stepDispatch = useStepDispatchContext();
    const calculatorInputDispatch = useCalculatorInputDispatchContext();
    const calculatorInput = useCalculatorInputContext();

    const [roofArea, setRoofArea] = useState<number | undefined>(calculatorInput.RoofAreaNumFloors?.roofArea);
    const [numFloors, setNumFloors] = useState<number | undefined>(calculatorInput.RoofAreaNumFloors?.numFloors);

    const [inputMode, setInputMode] = useState<Step4InputMode>(
        (numFloors) ? "numFloors" : "roofArea"
    );

    const [canProceed, setCanProceed] = useState<boolean>(
        inputMode === "roofArea" ? Boolean(roofArea) : Boolean(numFloors)
    );

    const [errWhenEmpty, setErrWhenEmpty] = useState<boolean>(false);

    const roofAreaId = useId();
    const numFloorsId = useId();
    const {buildingArea} = getBuildingArea(calculatorInput);
    const displayBuildingArea = Math.floor(buildingArea).toLocaleString();

    const roofAreaErr = errWhenEmpty && !roofArea;
    const numFloorsErr = errWhenEmpty && !numFloors;

    useEffect(() => {
        updateHeight();
        scrollToTop();
    }, []);

    function goToPrev() {
        stepDispatch({type: "goToPrev"});
    }

    function goToNext() {
        const dispatchValue = numFloors ?
            {numFloors: numFloors} as RoofAreaNumFloorsInput : {roofArea: Number(roofArea)} as RoofAreaNumFloorsInput;

        calculatorInputDispatch({
            type: "setRoofAreaNumFloors",
            value: dispatchValue
        });
        stepDispatch({type: "goToNext"});
    }

    function attemptGoToNext() {
        if (!canProceed) {
            setErrWhenEmpty(true);
        } else {
            goToNext();
        }
    }

    function switchInputMode(inputMode:Step4InputMode) {
        setErrWhenEmpty(false);
        setInputMode(inputMode);

        // Trigger field validation when switching input mode
        if (inputMode === "roofArea") {
            onChangeRoofArea(calculatorInput.RoofAreaNumFloors?.roofArea);
        } else if (inputMode === "numFloors") {
            onChangeNumFloors(calculatorInput.RoofAreaNumFloors?.numFloors);
        }
    }

    function onChangeRoofArea(value: number | false | undefined): void {
        setRoofArea(value || undefined);
        setNumFloors(undefined);

        if (Number(value) > buildingArea) {
            setCanProceed(false);
            // @todo Throw an error when roof area is greater than building area
        } else {
            setCanProceed(Boolean(value));
        }
    }

    function onChangeNumFloors(value: number | false | undefined): void {
        setRoofArea(undefined);
        setNumFloors(value || undefined);

        setCanProceed(Boolean(value));
    }

    function getRoofAreaMessage(): string {
        if (numFloors) {
            const displayNumFloors = numFloors.toLocaleString();
            const estimatedRoofArea = Math.floor(buildingArea / numFloors).toLocaleString();
            return `Total square footage ${displayBuildingArea} / No. of Floors ${displayNumFloors} = Est roof area ${estimatedRoofArea} sq. ft.`;
        }

        return "";
    }

    const ACDT = ancillaryContentDictionary.get(4)! as ContentACDT;
    const educationalContent = ACDT.content;

    return <Container className={SCSSModule["step4"]}>
        <ScreenHeader title={"Great! Let’s see what’s possible on your project."}
            subtitle={`We have your building area as ${displayBuildingArea} sq. ft. Please enter either the roof area or number of floors.`}/>

        <ContentRow firstMainContentRow={true}>
            <Col xs={12} md={8} xl={5} className={`ps-md-0 ${SCSSModule["step4-content"]}`}>
                <Form>
                    {inputMode === "roofArea" ? (
                        <NumericGroup controlId={roofAreaId}>
                            <Form.Label>Roof area</Form.Label>
                            <InputNumeric suffix={" sq. ft."} defaultValue={roofArea}
                                          onNumberInput={onChangeRoofArea} isInvalid={roofAreaErr}
                                          aria-describedby={roofAreaErr ? "roofAreaErr" : undefined}
                                          placeholder={"Enter sq. ft."}/>
                            {roofAreaErr &&
                                <Form.Text id="roofAreaErr" muted>
                                    Please provide a Number of floors or a Roof area in sq. ft.
                                </Form.Text>
                            }
                        </NumericGroup>
                    ) : (
                        <>
                            <NumericGroup controlId={numFloorsId}>
                                <Form.Label>Number of floors</Form.Label>
                                <InputNumeric key={numFloorsId} defaultValue={numFloors}
                                              onNumberInput={onChangeNumFloors} isInvalid={numFloorsErr}
                                              aria-describedby={numFloorsErr ? "numFloorsErr" : undefined}
                                              placeholder={"Enter number of floors"}/>
                                {numFloorsErr &&
                                    <Form.Text id="numFloorsErr" muted>
                                        Please provide a Number of floors or a Roof area in sq. ft.
                                    </Form.Text>
                                }
                            </NumericGroup>
                        </>
                    )}
                    <Button
                        variant="link"
                        onClick={() => {
                            switchInputMode(inputMode === "roofArea" ? "numFloors" : "roofArea");
                        }}
                    >
                        {inputMode === "roofArea" ? (
                            "Calculate with number of floors"
                        ) : (
                            "Enter roof area"
                        )}
                    </Button>
                    {inputMode === "numFloors" && <span className={SCSSModule["roof-area-message"]}>{numFloors && getRoofAreaMessage()}</span>}
                </Form>
            </Col>
            <Col xl={3} className={"pe-md-0"}>
                <EducationalPanel content={educationalContent.get(1)!.content} title={educationalContent.get(1)!.title ?? ""}/>
            </Col>
        </ContentRow>

        <StepNavigation goToPrev={goToPrev} goToNext={attemptGoToNext} nextDisabled={!canProceed}/>
    </Container>;
}

export default Step4;
