import React, {useState} from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import {ERMSelections, ReadonlyERMSelections, ERMId} from "types/ERMType";
import Button from "react-bootstrap/Button";
import {ERMIsValidForBuildingType} from "workers/helper";
import {useCalculatorInputContext} from "contexts/CalculatorInputContext";
import {Collapse} from "react-bootstrap";
import ErmSelectorList from "./ErmSelectorList";
import Row from "react-bootstrap/Row";
import SCSSModule from "./ErmEditor.module.scss";
import {addClassName} from "utils/general";
import ButtonIconText from "components/common/ButtonIconText/ButtonIconText";
import {updateHeight} from "utils/iframe";
import ButtonOutlineIcon from "../../../common/ButtonOutlineIcon/ButtonOutlineIcon";

function filterERMs(inputERMs: ERMSelections, selectedBuildingTypeId:BuildingTypeId): ERMSelections {
    const newSelectedERMs: ERMSelections = new Map();
    for (const [key, value] of inputERMs.entries()) {
        if (ERMIsValidForBuildingType(key, selectedBuildingTypeId)) {
            newSelectedERMs.set(key, value);
        }
    }

    return newSelectedERMs;
}

function ErmEditor({defaultSelectedERMs, updateERMs}:ErmEditorProps): React.ReactElement {
    // Do this first
    const calculatorInput = useCalculatorInputContext();
    const selectedBuildingTypeId = calculatorInput.BuildingInputContextValue!.BuildingTypeId!;
    defaultSelectedERMs = filterERMs(defaultSelectedERMs, selectedBuildingTypeId);

    const [showEditRemainingAdjustmentsButton, setShowEditRemainingAdjustmentsButton] = useState(true);
    const [openRemainingAdjustments, setOpenRemainingAdjustments] = useState(false);
    const [selectedERMs, setSelectedERMs]:[ReadonlyERMSelections, React.Dispatch<React.SetStateAction<ERMSelections>>] = useState(defaultSelectedERMs);
    const [applyDisabled, setApplyDisabled]:[Readonly<boolean>, React.Dispatch<React.SetStateAction<Readonly<boolean>>>] = useState<Readonly<boolean>>(true);

    function selectAllERMs(_event: React.MouseEvent) {
        const newSelectedERMs: ERMSelections = new Map(selectedERMs);
        Array.from(newSelectedERMs.entries()).filter(entry => {
            return newSelectedERMs.set(entry[0], true);
        });

        setApplyDisabled(false);

        return setSelectedERMs(newSelectedERMs);
    }

    function updateSelectedERMs(_event: React.MouseEvent) {
        return updateERMs(selectedERMs as ERMSelections);
    }

    function remainingAdjustmentsAvailable() {
        return Array.from(defaultSelectedERMs.entries()).filter((entry) => !entry[1]).length > 0;
    }

    function changeErmValue(event: React.ChangeEvent<HTMLInputElement>): void {
        const checkbox = event.currentTarget;
        const newSelectedERMs: ERMSelections = new Map(selectedERMs);
        newSelectedERMs.set(Number(checkbox.value) as ERMId, checkbox.checked);
        setSelectedERMs(newSelectedERMs);

        const was = JSON.stringify(Array.from(defaultSelectedERMs.entries()));
        const is = JSON.stringify(Array.from(newSelectedERMs.entries()));
        setApplyDisabled(was === is);
    }

    function closeAndExitClick(_event: React.MouseEvent) {
        setOpenRemainingAdjustments(false);
        setShowEditRemainingAdjustmentsButton(true);
        setSelectedERMs(defaultSelectedERMs);
        setApplyDisabled(true);
        setTimeout(updateHeight, 400);
    }

    function editClick(_event: React.MouseEvent) {
        setOpenRemainingAdjustments(true);
        setShowEditRemainingAdjustmentsButton(false);
        setTimeout(updateHeight, 400);
    }

    return <Container className={SCSSModule["erm-editor"]}>
        <Row className={SCSSModule["specific-adjustments"]}>
            <Col>
                <div className={SCSSModule["adjustments-label"]}>Your selected features</div>
                <ButtonOutlineIcon onClick={editClick} hidden={openRemainingAdjustments} symbol={"edit"} title={"Edit"} className={SCSSModule["edit-mobile"]}/>
                <ButtonIconText onClick={editClick} hidden={openRemainingAdjustments} symbol={"edit"} title={"Edit"} className={SCSSModule["edit"]}/>
            </Col>
        </Row>

        <Row className={addClassName(SCSSModule["adjustments-list"], openRemainingAdjustments ? SCSSModule["specific"] : "")}>
            <Col>
                <ErmSelectorList defaultSelectedERMs={defaultSelectedERMs} selectedERMs={selectedERMs}
                    showERMsWhenDefaultIs={true} disabled={showEditRemainingAdjustmentsButton}
                    onChange={changeErmValue} />
            </Col>
        </Row>

        <Row className={SCSSModule["remaining-adjustments"]} hidden={!openRemainingAdjustments}>
            <Col>
                {remainingAdjustmentsAvailable() && <>
                    <div className={SCSSModule["adjustments-label"]}>Remaining Building Adjustments</div>
                    <Button variant="link" onClick={selectAllERMs}>Select All</Button>
                </>}
            </Col>
        </Row>

        <Row className={addClassName(SCSSModule["adjustments-list"], SCSSModule["remaining"])} hidden={!openRemainingAdjustments}>
            <Col>
                <Collapse in={openRemainingAdjustments}>
                    <div id="remaining-adjustments" className={"remaining-adjustments"}>
                        {remainingAdjustmentsAvailable() && <>
                            <ErmSelectorList defaultSelectedERMs={defaultSelectedERMs} selectedERMs={selectedERMs}
                                showERMsWhenDefaultIs={false} disabled={showEditRemainingAdjustmentsButton}
                                onChange={changeErmValue} />
                        </>}
                    </div>
                </Collapse>
            </Col>
        </Row>

        <Row className={SCSSModule["remaining-adjustments-controls"]} hidden={!openRemainingAdjustments}>
            <Col>
                <Button onClick={updateSelectedERMs} variant={"primary"} className={applyDisabled ? "disabled" : ""} disabled={applyDisabled}>Apply</Button>
                <Button variant="link" onClick={closeAndExitClick}>Close & Exit</Button>
            </Col>
        </Row>
    </Container>;
}

export default ErmEditor;