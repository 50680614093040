// export type ERMId = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11;
export type ERMId = number & IntRange<1, 12>; // 11 + 1

export type ERMSelections = Map<ERMId, boolean>;
export type ReadonlyERMSelections = ReadonlyMap<ERMId, boolean>;

export enum ERMTypes {
    typeSystem = "system",
    typePerformance = "performance"
}

export type ERMData = {label: string, description: string, type: ERMTypes, symbol: MaterialSymbolValue};

export type ERMEntries = [ERMId, ERMData][];

export const ERMs: Map<ERMId, ERMData> = new Map([
    [1, {label: "Efficient electric heat pump heating", description: "", type: ERMTypes.typeSystem, symbol: "mode_heat"}],
    [2, {label: "Efficient electric heat pump hot water", description: "", type: ERMTypes.typeSystem, symbol: "water_ec"}],
    [3, {label: "Window upgrade", description: "Improve thermal performance and SHGC", type: ERMTypes.typePerformance, symbol: "window"}],
    [4, {label: "Wall upgrade", description: "Increase wall insulation levels", type: ERMTypes.typePerformance, symbol: "nest_multi_room"}],
    [5, {label: "Roof upgrade", description: "Increase roof insulation levels", type: ERMTypes.typePerformance, symbol: "roofing"}],
    [6, {label: "Infiltration reduction", description: "Improve building airtightness", type: ERMTypes.typePerformance, symbol: "water_pump"}],
    [7, {label: "Heat recovery ventilation", description: "Recover waste heat from ventilation air", type: ERMTypes.typePerformance, symbol: "mode_heat_cool"}],
    [8, {label: "Lighting power reductions", description: "Reduce installed lighting power by 20%", type: ERMTypes.typePerformance, symbol: "light"}],
    [9, {label: "All-electric commercial cooking", description: "Upgrade to efficient electric appliances", type: ERMTypes.typePerformance, symbol: "range_hood"}],
    [10, {label: "All-electric residential cooking", description: "Upgrade to efficient electric appliances", type: ERMTypes.typePerformance, symbol: "oven_gen"}],
    [11, {label: "High-efficiency heat pump dryer", description: "Upgrade to efficient heat pump dryers", type: ERMTypes.typePerformance, symbol: "local_laundry_service"}]
] as ERMEntries);