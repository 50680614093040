/* eslint-disable no-restricted-globals */
import React from "react";
import {ResultsLinkParams, getURLParamsHash} from "./ResultsUtils";
import {BuildingTypeId, buildingTypes} from "etc/BuildingTypes";
import HeatingType from "types/HeatingType";
import {ERMs, ERMSelections} from "types/ERMType";
import {RoofAreaNumFloorsInput} from "components/screens/Step4/Step4";
import {requestHash} from "utils/iframe";

let hashRequested:boolean = false;

class ResultsParamHandler {
    private hasParsedUpdatedParams:boolean = false;
    private calculatorInputDispatch!: React.Dispatch<CalculatorInputAction>;

    init = (
        calculatorInputDispatch: React.Dispatch<CalculatorInputAction>
    ) => {
        const thisClass = this;
        thisClass.calculatorInputDispatch = calculatorInputDispatch;

        if (!hashRequested) {
            requestHash(thisClass.parseUpdatedParams);
            hashRequested = true;
        }
    }

    private parseUpdatedParams = (
        payload: string
    ) => {
        const thisClass = this;

        if (typeof TextEncoder === "undefined") {
            // Silently skip intake; browser or Jest doesn't support TextEncoder
            return;
        }

        if (!thisClass.hasParsedUpdatedParams) {
            thisClass.hasParsedUpdatedParams = true;
        } else {
            console.warn('Updated params have already been parsed.');
            return;
        }

        if (payload.startsWith('#')) {
            payload = payload.slice(1);
        }

        const URLParams = new URLSearchParams(payload);
        const inputHMAC = URLParams.get(ResultsLinkParams.HMAC);

        // Delete the HMAC and recalculate an HMAC from the rest of the vars, then compare
        const checkHMAX = getURLParamsHash(URLParams);

        console.log({
            URLParams: Array.from(URLParams.entries()),
            inputHMAC: inputHMAC,
            checkHMAC: checkHMAX
        });

        if (inputHMAC !== checkHMAX) {
            console.error(`Updated params failed HMAC validation and will be ignored.`);
            return;
        }

        console.debug('Dispatching new user input from URL', URLParams);

        const triggerLoadingEvent = new CustomEvent("pge.trigger-loading");
        document.dispatchEvent(triggerLoadingEvent);

        try {
            const updateBuildingInput = thisClass.intakeBuildingInput(URLParams);
            const updateSolarInput = thisClass.intakeSolarInput(URLParams);
            const updateFlexLoadInput = thisClass.intakeFlexLoadInput(URLParams);

            let updateRoofAreaInput:CalculatorInputAction|null = null;
            if (updateSolarInput.value) {
                updateRoofAreaInput = thisClass.intakeRoofAreaInput(URLParams);
            }

            const heatingSourceInput = thisClass.intakeHeatingSourceInput(URLParams);
            const ERMsInput = thisClass.intakeERMsInput(URLParams);

            thisClass.calculatorInputDispatch(updateBuildingInput);
            thisClass.calculatorInputDispatch(updateSolarInput);
            thisClass.calculatorInputDispatch(updateFlexLoadInput);
            if (updateRoofAreaInput) {
                thisClass.calculatorInputDispatch(updateRoofAreaInput);
            }
            thisClass.calculatorInputDispatch(heatingSourceInput);
            thisClass.calculatorInputDispatch(ERMsInput);
        } catch (e:unknown) {
            const message = (e instanceof Error) ? e.message : "unknown error";
            console.warn(`Couldn't dispatch updated Calculator Input: ${message}`)
        }
    }

    private intakeBuildingInput = (URLParams:URLSearchParams): CalculatorInputAction => {
        const buildingTypeId = URLParams.get(ResultsLinkParams.buildingTypeId) as BuildingTypeId;
        if (!buildingTypes.get(buildingTypeId)) {
            throw new Error(`Invalid buildingTypeId ${JSON.stringify(buildingTypeId)}`)
        }
        return {
            type: "setBuildingInputContextValue",
            value: {
                BuildingTypeId: buildingTypeId,
                BuildingArea: URLParams.get(ResultsLinkParams.buildingArea) ? Number(URLParams.get(ResultsLinkParams.buildingArea)) : undefined,
                UnitCount: URLParams.get(ResultsLinkParams.unitCount) ? Number(URLParams.get(ResultsLinkParams.unitCount)) : undefined,
            }
        }
    }

    private intakeSolarInput = (URLParams:URLSearchParams): CalculatorInputAction => {
        return {
            type: "setSolarContextValue",
            value: URLParams.has(ResultsLinkParams.roofArea)
        }
    }

    private intakeFlexLoadInput = (URLParams:URLSearchParams): CalculatorInputAction => {
        return {
            type: "setFlexibleLoadValue",
            value: URLParams.has(ResultsLinkParams.flexLoad)
        }
    }

    private intakeRoofAreaInput = (URLParams:URLSearchParams): CalculatorInputAction => {
        return {
            type: "setRoofAreaNumFloors",
            value: {
                roofArea: Number(URLParams.get(ResultsLinkParams.roofArea))
            } as RoofAreaNumFloorsInput
        }
    }

    private intakeHeatingSourceInput = (URLParams:URLSearchParams): CalculatorInputAction => {
        return {
            type: "setHeatingSourceInput",
            value: {
                spaceHeatingType: URLParams.get(ResultsLinkParams.spaceHeatingType) as HeatingType,
                DHWHeatingType: URLParams.get(ResultsLinkParams.DHWHeatingType) as HeatingType,
                ...(URLParams.has(ResultsLinkParams.existingElectricConsumption) && {existingElectricConsumption: Number(URLParams.get(ResultsLinkParams.existingElectricConsumption))}),
                ...(URLParams.has(ResultsLinkParams.existingGasConsumption) && {existingGasConsumption: Number(URLParams.get(ResultsLinkParams.existingGasConsumption))})
            }
        }
    }

    private intakeERMsInput = (URLParams:URLSearchParams): CalculatorInputAction => {
        const ERMSelections:ERMSelections = new Map();

        ERMs.forEach((_name, ERMId) => {
            ERMSelections.set(ERMId, URLParams.get(`${ResultsLinkParams.ERMSelection}[${ERMId}]`) === "1")
        });

        return {
            type: "setERMsInput",
            value: ERMSelections
        }
    }
}

const resultsParamHandler = new ResultsParamHandler();
export default resultsParamHandler;