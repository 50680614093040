import React, {useEffect, useMemo, useState, useId} from "react";
import {useStepDispatchContext} from "contexts/StepContext";
import {useBuildingInputContext, useBuildingInputDispatchContext} from "contexts/BuildingContext";
import {
    BuildingType,
    buildingTypes,
    midriseBuildingIncludingARestaurant,
    midriseBuildingWithoutARestaurant,
    multifamilyHighRise
} from "etc/BuildingTypes";
import {buildMessage, WorkerProcesses} from "workers/helper";
import WorkerFactory from "workers/calculate-results-factory";
import InputNumeric from "components/common/InputNumeric/InputNumeric";
import {useCalculatorInputContext} from "contexts/CalculatorInputContext";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Form from 'react-bootstrap/Form';
import ContentRow from "components/helpers/Grid/ContentRow";
import NumericGroup from "components/common/NumericGroup/NumericGroup";
import ScreenHeader from "components/common/ScreenHeader/ScreenHeader";
import EducationalPanel from "components/common/EducationalPanel/EducationalPanel";
import ancillaryContentDictionary, {ContentACDT} from "components/common/AncillaryContentModal/AncillaryContent";
import SCSSModule from "./Step2.module.scss";
import StepNavigation from "components/common/StepNavigation/StepNavigation";
import {scrollToTop, updateHeight} from "utils/iframe";

function Step2(): React.ReactElement {
    const stepDispatch = useStepDispatchContext();
    const buildingInput = useBuildingInputContext();
    const buildingInputDispatch = useBuildingInputDispatchContext();
    const calculatorInput = useCalculatorInputContext();

    const [buildingArea, setBuildingArea] = useState<number | undefined>(calculatorInput.BuildingInputContextValue?.BuildingArea);
    const [unitCount, setUnitCount] = useState<number | undefined>(calculatorInput.BuildingInputContextValue?.UnitCount);

    const paeWorker: Worker = useMemo(
        () => WorkerFactory(),
        []
    );

    const isUnitCountAvailable = useMemo(() => {
        const selectedBuildingTypeId = buildingInput?.BuildingTypeId;
        return selectedBuildingTypeId === multifamilyHighRise.id
            || selectedBuildingTypeId === midriseBuildingWithoutARestaurant.id
            || selectedBuildingTypeId === midriseBuildingIncludingARestaurant.id;
    }, [buildingInput]);

    const canProceed = useMemo(() => {
        const buildingAreaIsValid = Boolean(buildingArea);
        const unitCountIsValid = isUnitCountAvailable ? Boolean(unitCount) : true;
        return buildingAreaIsValid && unitCountIsValid;
    }, [isUnitCountAvailable, buildingArea, unitCount]);

    const buildingAreaId = useId();
    const unitCountId = useId();

    const [errWhenEmpty, setErrWhenEmpty] = useState<boolean>(false);
    const buildingAreaErr = errWhenEmpty && !buildingArea;
    const unitCountErr = errWhenEmpty && !unitCount;

    if (typeof buildingInput === "undefined") {
        throw new Error("Building Type must be selecting before coming to Step 2");
    }
    const selectedBuildingTypeId:BuildingTypeId = buildingInput.BuildingTypeId;
    const selectedBuildingType:BuildingType = buildingTypes.get(selectedBuildingTypeId)!;

    useEffect(() => {
        updateHeight();
        scrollToTop();
    }, []);

    /*
     * Preload the worker so that it's loaded in the user's browser long before step 7
     */
    useEffect(() => {
        if (window.Worker) {
            paeWorker.postMessage(
                buildMessage(WorkerProcesses.preload)
            );
        }
    }, [paeWorker]);

    function goToPrev() {
        stepDispatch({type: "goToPrev"});
    }

    function goToNext() {
        buildingInputDispatch({
            type: "setBuildingArea",
            BuildingArea: buildingArea,
            ...(isUnitCountAvailable && {UnitCount: unitCount})
        });
        stepDispatch({type: "goToNext"});
    }

    function attemptGoToNext() {
        if (!canProceed) {
            setErrWhenEmpty(true);
        } else {
            goToNext();
        }
    }

    function onChangeBuildingArea(value: number | false): void {
        setBuildingArea(value || undefined);
    }

    function onChangeUnitCount(value: number | false): void {
        setUnitCount(value || undefined);
    }

    const ACDT = ancillaryContentDictionary.get(2)! as ContentACDT;
    const educationalContent = ACDT.content.get(selectedBuildingType)!;

    return <Container className={SCSSModule["step2"]}>
        <ScreenHeader title={"Let’s customize your project"}/>

        <ContentRow firstMainContentRow={true}>
            <Col xs={12} md={8} xl={5} className={`px-md-0 ps-xl-0 pe-xl-2 ${SCSSModule["left-content-col"]}`}>
                <NumericGroup controlId={buildingAreaId}>
                    <Form.Label>Building area</Form.Label>
                    <InputNumeric suffix={" sq. ft."} defaultValue={buildingArea}
                                  onNumberInput={onChangeBuildingArea} isInvalid={buildingAreaErr}
                                  aria-describedby={buildingAreaErr ? "buildingAreaErr" : undefined}
                                  placeholder={"Enter sq. ft."}/>

                    {buildingAreaErr &&
                        <Form.Text id="buildingAreaErr" muted>
                            Please enter the building area
                        </Form.Text>
                    }
                </NumericGroup>

                {isUnitCountAvailable && (
                    <NumericGroup controlId={unitCountId}>
                        <Form.Label>Unit count</Form.Label>
                        <InputNumeric suffix={" units"} defaultValue={unitCount}
                                      onNumberInput={onChangeUnitCount} isInvalid={unitCountErr}
                                      aria-describedby={unitCountErr ? "unitCountErr" : undefined}
                                      placeholder={"Enter number of units"}/>
                        {unitCountErr &&
                            <Form.Text id="unitCountErr" muted>
                                Please enter the number of units
                            </Form.Text>
                        }
                    </NumericGroup>
                )}
            </Col>
            <Col xl={3} className={"d-none d-xl-block ps-xl-2 pe-xl-0"}>
                <EducationalPanel content={educationalContent.content} title={educationalContent.title ?? ""} className={"step2-building-size"}/>
            </Col>
        </ContentRow>

        <StepNavigation goToPrev={goToPrev} goToNext={attemptGoToNext} nextDisabled={!canProceed}/>
    </Container>;
}

export default Step2;