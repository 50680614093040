import {ProcessedInput} from "types/InputProcessorType";
import pge from "utils/pge";

export enum ResultsLinkParams {
    buildingTypeId = "BTID",
    buildingArea = "BA",
    buildingStories = "BS",
    roofArea = "RA",
    spaceHeatingType = "SPHT",
    DHWHeatingType = "DHW",
    existingElectricConsumption = "EEC",
    existingGasConsumption = "EGC",
    ERMSelection = "ERM",
    unitCount = "UC",
    flexLoad = "FL",
    linkVersion = "LV",
    HMAC = "HMAC"
}

type BooleanString = "1" | "0";
type ERMParamKey = `${ResultsLinkParams.ERMSelection}[${number}]`;

type ResultsLinkAttributes = {
    [ResultsLinkParams.buildingTypeId]: string,
    [ResultsLinkParams.buildingArea]?: string,
    [ResultsLinkParams.buildingStories]?: string,
    [ResultsLinkParams.roofArea]: string,
    [ResultsLinkParams.spaceHeatingType]: string,
    [ResultsLinkParams.DHWHeatingType]: string,
    [ResultsLinkParams.existingElectricConsumption]?: string,
    [ResultsLinkParams.existingGasConsumption]?: string,
    [key: ERMParamKey]: BooleanString,
    [ResultsLinkParams.unitCount]?: string,
    [ResultsLinkParams.flexLoad]: BooleanString,
    [ResultsLinkParams.linkVersion]: string,
    [ResultsLinkParams.HMAC]?: string
}

const semiSecret = "PAE-GHG-Calculator";

function imul36Hash(message: string) {
    return Math.abs([...semiSecret.concat(message)]
        .reduce((hash, c) => (Math.imul(31, hash) + c.charCodeAt(0)) | 0, 0))
        .toString(36);
}

export function getURLParamsHash(URLParams: URLSearchParams) {
    URLParams.delete("HMAC");
    URLParams.sort();
    return imul36Hash(URLParams.toString());
}

export function outputERMParams(processedInput: ProcessedInput): Map<ERMParamKey, BooleanString> {
    const ERMSelectionBools: { [k: string]: boolean } = Object.fromEntries(processedInput.ERMSelections.entries());

    let ERMSelectionParams: Map<ERMParamKey, BooleanString> = new Map();
    Object.keys(ERMSelectionBools).forEach(k => {
        ERMSelectionParams.set(`${ResultsLinkParams.ERMSelection}[${k}]` as ERMParamKey, String(Number(ERMSelectionBools[k])) as BooleanString);
    });

    return ERMSelectionParams;
}

export function outputResultParams(processedInput: ProcessedInput): string {
    let inputObject: ResultsLinkAttributes = {
        [ResultsLinkParams.buildingTypeId]: String(processedInput.buildingTypeId),
        [ResultsLinkParams.buildingArea]: String(processedInput.buildingArea),
        [ResultsLinkParams.roofArea]: String(Number(processedInput.roofArea)),
        [ResultsLinkParams.spaceHeatingType]: processedInput.spaceHeatingType,
        [ResultsLinkParams.DHWHeatingType]: processedInput.DHWHeatingType,
        ...(processedInput.existingElectricConsumption && {[ResultsLinkParams.existingElectricConsumption]: String(processedInput.existingElectricConsumption)}),
        ...(processedInput.existingGasConsumption && {[ResultsLinkParams.existingGasConsumption]: String(processedInput.existingGasConsumption)}),
        ...(processedInput.unitCount && {[ResultsLinkParams.unitCount]: String(processedInput.unitCount)}),
        [ResultsLinkParams.flexLoad]: processedInput.flexibleLoad ? "1" : "0",
        [ResultsLinkParams.linkVersion]: "1.1"
    };

    outputERMParams(processedInput).forEach((value, key) => {
        inputObject[key] = value;
    });

    let inputAsParams = new URLSearchParams(inputObject);

    const HMAC = getURLParamsHash(inputAsParams);
    inputAsParams.set("HMAC", HMAC);

    return new URLSearchParams(inputAsParams).toString()
        .replaceAll("%5B", "[")
        .replaceAll("%5D", "]");
}

export function outputResultURL(processedInput: ProcessedInput): string {
    return pge.targetURL.concat("#").concat(outputResultParams(processedInput));
}