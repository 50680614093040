import React, {createContext, useContext, useEffect, useReducer} from "react";
import {
    midriseBuildingIncludingARestaurant,
    midriseBuildingWithoutARestaurant,
    multifamilyHighRise
} from "etc/BuildingTypes";
import {useCalculatorInputDispatchContext} from "./CalculatorInputContext";

export function BuildingInputContextReducer(currentBuildingInput: BuildingInput | undefined, action: UpdateBuildingInputAction): BuildingInput | undefined {
    /*
     * NOTE: TS will enforce BuildingTypeId, so this reducer is too simple to require unit testing
     */
    switch (action.type) {
        case "setBuildingType":
            return {
                ...currentBuildingInput,
                BuildingTypeId: action.BuildingTypeId
            };
        case "setBuildingArea":
            if (!currentBuildingInput?.BuildingTypeId) {
                throw new Error('setBuildingType must be called before setBuildingArea');
            }
            const newBuildingInput = {
                ...currentBuildingInput,
                BuildingArea: action.BuildingArea,
            };
            if (action.UnitCount
                && (
                    currentBuildingInput.BuildingTypeId === multifamilyHighRise.id
                    || currentBuildingInput.BuildingTypeId === midriseBuildingWithoutARestaurant.id
                    || currentBuildingInput.BuildingTypeId === midriseBuildingIncludingARestaurant.id
                )
            ) {
                newBuildingInput.UnitCount = action.UnitCount;
            } else {
                delete newBuildingInput.UnitCount;
            }
            return newBuildingInput;
        default:
            throw new Error(`Invalid action type ${action["type"]}`);
    }
}

const BuildingInputContext = createContext<BuildingInput | undefined>(undefined);
const BuildingInputDispatchContext = createContext(null as unknown as React.Dispatch<UpdateBuildingInputAction>);

export function BuildingInputContextProvider({children}: { children: React.ReactElement | React.ReactElement[] }) {
    const [BuildingTypeInput, dispatch] = useReducer(
        BuildingInputContextReducer,
        undefined
    );

    const calculatorInputDispatch = useCalculatorInputDispatchContext();

    /*
     * This fires after dispatch() is complete so that we can bubble up the context value to CalculatorInputContext
     */
    useEffect(() => {
        if (BuildingTypeInput) {
            calculatorInputDispatch({
                type: "setBuildingInputContextValue",
                value: BuildingTypeInput
            });
        }
    }, [BuildingTypeInput, calculatorInputDispatch]);

    return (
        <BuildingInputContext.Provider value={BuildingTypeInput}>
            <BuildingInputDispatchContext.Provider value={dispatch}>
                {children}
            </BuildingInputDispatchContext.Provider>
        </BuildingInputContext.Provider>
    );
}

export function useBuildingInputContext() {
    return useContext(BuildingInputContext);
}

export function useBuildingInputDispatchContext() {
    return useContext(BuildingInputDispatchContext);
}