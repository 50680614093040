export type BuildingTypeId = "0"|"1"|"2"|"3"|"4";
export type BuildingType = {
        id: BuildingTypeId,
        name: string,
        code: BuildingTypeCode,
        defaultArea: number,
        bestERMRef: number,
        description?: string,
        icon?: string,
        column?: number
    }
export const buildingTypes:Map<BuildingTypeId, BuildingType> = new Map();
export const commercialOfficeBuilding:BuildingType = {
            id: "0",
            name: "Commercial Office Building",
            code: "commercialOfficeBuilding",
            defaultArea: 53633.04,
            bestERMRef: 2040
        };
buildingTypes.set("0", commercialOfficeBuilding);
export const educationalFacility:BuildingType = {
            id: "1",
            name: "Educational Facility",
            code: "educationalFacility",
            defaultArea: 210907.12,
            bestERMRef: 2044
        };
buildingTypes.set("1", educationalFacility);
export const multifamilyHighRise:BuildingType = {
            id: "2",
            name: "Multifamily High Rise",
            code: "multifamilyHighRise",
            defaultArea: 84359.39,
            bestERMRef: 2043
        };
buildingTypes.set("2", multifamilyHighRise);
export const midriseBuildingWithoutARestaurant:BuildingType = {
            id: "3",
            name: "Midrise Building Without a Restaurant",
            code: "midriseBuildingWithoutARestaurant",
            defaultArea: 50615.64,
            bestERMRef: 2043
        };
buildingTypes.set("3", midriseBuildingWithoutARestaurant);
export const midriseBuildingIncludingARestaurant:BuildingType = {
            id: "4",
            name: "Midrise Building Including a Restaurant",
            code: "midriseBuildingIncludingARestaurant",
            defaultArea: 50615.64,
            bestERMRef: 2047
        };
buildingTypes.set("4", midriseBuildingIncludingARestaurant);
export type BuildingTypeCode = "commercialOfficeBuilding"|"educationalFacility"|"multifamilyHighRise"|"midriseBuildingWithoutARestaurant"|"midriseBuildingIncludingARestaurant";