import React from "react";
import {StepContextProvider} from "contexts/StepContext";
import {BuildingInputContextProvider} from "contexts/BuildingContext";
import {SolarContextProvider} from "contexts/SolarContext";
import {useResultsContext} from "contexts/ResultsContext";
import StepVisualizer from "components/common/StepVisualizer/StepVisualizer";
import StepRenderer from "./StepRenderer";
import ResultsScreen from "components/screens/Results/Results";

function ResultsSwitcher(): React.ReactElement | null {
    const results = useResultsContext();

    return  <StepContextProvider>
        {!results ?
            <>
                <StepVisualizer/>
                <BuildingInputContextProvider>
                    <StepRenderer step={1}/>
                    <StepRenderer step={2}/>
                </BuildingInputContextProvider>
                <SolarContextProvider>
                    <StepRenderer step={3}/>
                    <StepRenderer step={4}/>
                    <StepRenderer step={5}/>
                </SolarContextProvider>
                <StepRenderer step={6}/>
                <StepRenderer step={7}/>
            </>
        : <></>}
        {window.Worker &&
            <ResultsScreen/>
        }
    </StepContextProvider>;

}

export default ResultsSwitcher;
