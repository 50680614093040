import React, {forwardRef} from "react";
import SCSSModule from "./ButtonOutlineIcon.module.scss";
import {addClassName} from "utils/general";

const ButtonOutlineIcon = forwardRef(
    ({symbol, onClick, title, onBlue, disabled, ...rest}: ButtonOutlineIconProps, ref:React.ForwardedRef<HTMLDivElement>): React.ReactElement => {
        let classNames = [
            "material-symbols-rounded",
            SCSSModule["self"]
        ];

        if (onBlue) {
            classNames.push(SCSSModule["on-blue"])
        }

        if (disabled) {
            classNames.push(SCSSModule["disabled"])
            onClick = () => {};
        }

        rest.className = addClassName(rest.className, classNames.join(" "));

        return (
            <div onClick={onClick} title={title} ref={ref} {...rest}>{symbol}</div>
        );
    }
);

export default ButtonOutlineIcon;