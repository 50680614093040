import Modal from "react-bootstrap/Modal";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import React, {useEffect, useId, useState} from "react";
import ContentRow from "components/helpers/Grid/ContentRow";
import SCSSModule from "./FormModal.module.scss";
import {identifyLead} from "utils/salesforce";
import Form from "react-bootstrap/Form";
import {addClassName} from "utils/general";
import {hasPolyfill} from "utils/has-polyfill";
import RAF from "utils/RAF";
import {scrollToTop, updateHeight} from "utils/iframe";

function FormModal({showModal, setShowModal}: {showModal: boolean, setShowModal: React.Dispatch<React.SetStateAction<boolean>>}): React.ReactElement {
    const handleCloseModal = () => {
        setShowModal(false);
        setSubmitted(false);
    }

    const modalId = useId();
    const [validated, setValidated] = useState(false);
    const [submitted, setSubmitted] = useState(false);

    const handleSubmit = (event: React.FormEvent) => {
        const form = event.currentTarget as HTMLFormElement;

        // Don't use native browser form submission
        event.preventDefault();
        event.stopPropagation();

        if (!form.checkValidity()) {
            form.classList.add("was-validated");
            form.querySelectorAll('input:invalid').forEach((input, _key, _parent) => {
                hasPolyfill(input, `.${SCSSModule["form-group"]}`)
            });
            return;
        }

        setValidated(true);

        identifyLead({
            Email: form.email?.value,
            FirstName: form.firstName?.value,
            LastName: form.lastName?.value,
            Company: form.company?.value,
            Phone: form.phone?.value,
            Title: form.userTitle?.value
        }).then(() => {
            setSubmitted(true);
        });
    };

    const pollyfillHasInvalid = (event: React.ChangeEvent<HTMLInputElement>): void => {
        hasPolyfill(event.currentTarget, `.${SCSSModule["form-group"]}`)
    }

    useEffect(() => {
        RAF.setTimeout(updateHeight, 250);
        scrollToTop();
    }, [showModal, submitted]);

    return <Modal
        show={showModal}
        onHide={handleCloseModal}
        fullscreen={true}
        aria-labelledby={modalId}
        className={!submitted ? SCSSModule["modal-form"] : addClassName(SCSSModule["modal-form"], SCSSModule["modal-submitted"])}
    >
        {!submitted ? (
        <Container className={SCSSModule["container"] + ' reduce-bs-gutter'}>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <ContentRow rowClassName={"modal-head"}>
                    <Col className={"px-md-0"}>
                        <div className={SCSSModule["center"]}>
                            <h3>Let’s talk</h3>
                            <p>
                                We are excited to learn more about your project. Please provide your contact info below
                                and we will be in touch shortly.
                            </p>
                        </div>
                    </Col>
                </ContentRow>

                <ContentRow rowClassName={"main-content"}>
                    <Col className={"px-md-0"}>
                        <div>
                            <Row>
                                <Col xl={6} md={6}>
                                    <Form.Group className={SCSSModule["form-group"]}>
                                        <div className={SCSSModule["label-control-container"]}>
                                            <Form.Label>First name</Form.Label>
                                            <Form.Control required type="text" name={"firstName"} onChange={pollyfillHasInvalid} placeholder={"Jane"} />
                                        </div>
                                        <Form.Control.Feedback type="invalid">
                                            Please enter First name
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col xl={6} md={6}>
                                    <Form.Group className={SCSSModule["form-group"]}>
                                        <div className={SCSSModule["label-control-container"]}>
                                            <Form.Label>Last name</Form.Label>
                                            <Form.Control required type="text" name={"lastName"} onChange={pollyfillHasInvalid} placeholder={"Doe"} />
                                        </div>
                                        <Form.Control.Feedback type="invalid">
                                            Please enter Last name
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row>
                                <Col xl={12}>
                                    <Form.Group className={SCSSModule["form-group"]}>
                                        <div className={SCSSModule["label-control-container"]}>
                                            <Form.Label>Email</Form.Label>
                                            <Form.Control required type="email" name={"email"} onChange={pollyfillHasInvalid} placeholder={"jane.doe@biz.com"} />
                                        </div>
                                        <Form.Control.Feedback type="invalid">
                                            Please enter Email address
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row>
                                <Col xl={12}>
                                    <Form.Group className={SCSSModule["form-group"]}>
                                        <div className={SCSSModule["label-control-container"]}>
                                            <Form.Label>Company <i>(optional)</i></Form.Label>
                                            <Form.Control type="text" name={"company"} placeholder={"Jane’s Company"} />
                                        </div>
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row>
                                <Col xl={12}>
                                    <Form.Group className={SCSSModule["form-group"]}>
                                            <div className={SCSSModule["label-control-container"]}>
                                            <Form.Label>Phone <i>(optional)</i></Form.Label>
                                            <Form.Control type="text" name={"phone"} placeholder={"000-000-000"} />
                                        </div>
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row>
                                <Col xl={12}>
                                    <Form.Group className={SCSSModule["form-group"]}>
                                        <div className={SCSSModule["label-control-container"]}>
                                            <Form.Label>Title <i>(optional)</i></Form.Label>
                                            <Form.Control type="text" name={"userTitle"} placeholder={"Developer"} />
                                        </div>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </ContentRow>

                <ContentRow rowClassName={"modal-cta"}>
                    <Col className={"px-md-0"}>
                        <Row className={SCSSModule["center"]}>
                            <Col xl={12}>
                                <Button variant="primary" type={"submit"} className={"on-yellow"}>
                                    Submit request
                                </Button>
                            </Col>
                        </Row>
                        <Row className={SCSSModule["center"]}>
                            <Col xl={12}>
                                <Button variant="link" onClick={handleCloseModal}>
                                    Cancel request
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </ContentRow>
            </Form>
        </Container>
        ) : (
            <Container className={SCSSModule["container"] + ' reduce-bs-gutter'}>
                <ContentRow rowClassName={"modal-head"}>
                    <Col className={"px-md-0"}>
                        <div className={SCSSModule["center"]}>
                            <h3>Thank you</h3>
                            <p>
                                We appreciate you reaching out. You can expect a response within two business days.
                            </p>
                            <p>
                                Thank you for helping us continue to provide clean, reliable energy to Oregonians!
                            </p>
                        </div>
                    </Col>
                </ContentRow>
                <ContentRow rowClassName={"modal-cta"}>
                    <Col className={"px-md-0"}>
                        <Row className={SCSSModule["center"]}>
                            <Col xl={12}>
                                <Button variant="primary" onClick={handleCloseModal}>
                                    Return to my results
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </ContentRow>
            </Container>
        )}
    </Modal>;
}

export default FormModal;