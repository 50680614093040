import {BuildingTypeId} from "etc/BuildingTypes";
import HeatingType from "./HeatingType";
import {ERMSelections} from "./ERMType";

export type InputProcessorType = {
    buildingTypeId: BuildingTypeId,
    buildingArea?: number,
    buildingStories?: number,
    roofArea?: number,
    spaceHeatingType: HeatingType,
    DHWHeatingType: HeatingType,
    existingElectricConsumption?: number,
    existingGasConsumption?: number
    ERMSelections: ERMSelections,
    flexibleLoad: boolean,
    unitCount?: number
}

export type ProcessedInput = {
    buildingTypeId: BuildingTypeId,
    buildingArea: number,
    ERMSelections: ERMSelections,
    spaceHeatingType: HeatingType,
    DHWHeatingType: HeatingType,
    existingElectricConsumption: number | undefined,
    existingGasConsumption: number | undefined,
    baselineKWh: number,
    baselineTherm: number,
    prototypeArea: number,
    prototypeKWh: number,
    prototypeTherm: number,
    bestKWh: number,
    bestTherm: number,
    peakDemand: number,
    referenceKWh: number,
    referenceTherm: number,
    improvedKWh: number,
    improvedTherm: number,
    savingsKWh: number,
    savingsTherm: number,
    bestKWhScaled: number,
    bestThermScaled: number,
    roofArea: number | false,
    PVPotential: number | false,
    shiftPotential: number,
    flexibleLoad: boolean,
    unitCount: number | false
}

export type YearlyValuesResult = {
    referenceGHG: number,
    improvedGHG: number,
    improvedGHGwSolar: number,
    bestGhg: number,
    savingsGHG: number,
    PVGHGOffset?: number,
    referenceCost: number,
    improvedCost: number,
    savingsCost: number,
    PVCostOffset?: number,
}

export type YearlyValuesResultMap = Map<number, YearlyValuesResult>

export type YearlySumKey = keyof YearlyValuesResult;

export type YearlySumResult = Map<YearlySumKey, Map<number, number>>

export enum TreeUnit {
    trees = "trees",
    acres = "acres"
}

export type TreesResult = {
    value: number,
    unit: TreeUnit
};

export type SumResult = {
    totalReferenceGHG: number,
    totalImprovedGHG: number,
    totalBestGHG: number,
    totalSavingsGHG: number,
    totalPVGHGOffset: number,
    totalNetSavingGHG: number,
    totalNetGHG: number,
    totalReferenceCost: number,
    totalImprovedCost: number,
    totalSavingsCost: number,
    totalPVCostOffset: number,
    totalNetSavingsCost: number,
    totalNetCost: number,
    totalTreesEquivalent: TreesResult,
    getTotalBestTreesEquivalentDiff: TreesResult,
    referenceEmissions: number,
    improvedEmissions: number,
    optimizedEmissions: number,
}

export type YearOneResult = {
    year1ReferenceCost: number,
    year1ImprovedCost: number,
    year1SavingsCost: number,
    year1PVCostOffset: number,
    year1NetSavingsCost: number,
    year1NetCost: number,
}

export type InputProcessorResult = null | {
    processedInput: ProcessedInput,
    yearlyValues: YearlyValuesResultMap,
    yearlySums: YearlySumResult,
    yearOne: YearOneResult,
    sums: SumResult
}

export default InputProcessorType;