import React, {useEffect, useState} from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import ContentRow from "components/helpers/Grid/ContentRow";
import SCSSModule from "./Step7.module.scss";
import RAF from "utils/RAF";
import {scrollToTop, updateHeight} from "utils/iframe";

function Step7(): React.ReactElement {
    const [currentString, setCurrentString] = useState<number>(1);

    useEffect(() => {
        updateHeight();
        scrollToTop();
    }, []);

    useEffect(() => {
        RAF.setTimeout(function() {
            const nextString = currentString >= 3 ? 1 : currentString+1;
            setCurrentString(nextString);
        }, 1000);
    }, [currentString]);

    const animationWidth = "16.6875rem";
    const animationHeight = "17.32665rem";
    const animationScriptTag = {__html:`<lottie-player src="/pge-loading-animation.json" background="Transparent" speed="1" style="width: ${animationWidth}; height: ${animationHeight}" direction="1" mode="normal" loop autoplay></lottie-player>`}

    return <Container>
        <ContentRow>
            <Col className={"px-md-0"}>
                <div className={SCSSModule["content-container"]}>
                    <div className={SCSSModule["animation-container"]} style={{width: animationWidth, height: animationHeight}} dangerouslySetInnerHTML={animationScriptTag} />
                    {currentString === 1 && <h4>Building plans...</h4>}
                    {currentString === 2 && <h4>Reducing emissions...</h4>}
                    {currentString === 3 && <h4>Making an impact...</h4>}
                </div>
            </Col>
        </ContentRow>
    </Container>;
}

export default Step7;
