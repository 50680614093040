import {VictoryChart, VictoryBar, VictoryAxis, VictoryLabel} from "victory";
import React, {useRef} from "react";
import colors from "styles/useables/_Colors.scss";
import fonts from "styles/useables/_Fonts.scss";
import GraphSCSS from "./Graph.module.scss";
import GraphFontMath from "components/helpers/GraphFontMath";

enum ChartCategories {
    GAS = "Baseline",
    YOURS = "Your\nimproved",
    OPTIMIZED = "Fully\noptimized"
}

function Graph({sumResults}:SumEmissionsProps): React.ReactElement {
    const refSVG = useRef<null | HTMLDivElement>(null);

    const datumLabel = ({datum}:{datum:{y:number}}) => `${Math.floor(datum.y)}\nMTCO2e`;

    const {
        graphFontSize
    } = GraphFontMath({
        refSVG
    });

    return <div className={GraphSCSS['sub-emissions']} ref={refSVG}>
        <h4>20 year operational emissions</h4>
        <VictoryChart minDomain={{x: 0.25}} maxDomain={{x: 3.75}}>
            {/* X axis */}
            <VictoryAxis
                offsetY={49}
                style={{
                    axis: {
                        stroke: colors["starlight-blue"],
                        strokeWidth: 2,
                        fontSize: graphFontSize
                    },
                    axisLabel: {
                        fill: colors["starlight-blue"],
                        textAnchor: 'start',
                        fontSize: graphFontSize
                    },
                    tickLabels: {
                        fill: colors["starlight-blue"],
                        fontFamily: fonts["font-family"],
                        fontWeight: 600,
                        fontSize: graphFontSize
                    },
                }}
            />

            <VictoryBar
                barRatio={1.25}
                cornerRadius={{ topLeft: ({ datum }) => datum.x === ChartCategories.OPTIMIZED ? 6 : 0, topRight: ({ datum }) => datum.x === ChartCategories.OPTIMIZED ? 6 : 0 }}
                style={{
                    data: {
                        fill: ({ datum }) => datum.x === ChartCategories.OPTIMIZED ? colors["elec-yellow"] : colors["light-gray-3"],
                        stroke: ({ datum }) => datum.x === ChartCategories.OPTIMIZED ? colors["solar-yellow"] : colors["light-gray-3"],
                        strokeWidth: 1,
                    }
                }}
                categories={{x: [ChartCategories.GAS, ChartCategories.YOURS, ChartCategories.OPTIMIZED]}}
                data={[
                    { x: ChartCategories.GAS, y: sumResults.totalReferenceGHG, label: datumLabel },
                    { x: ChartCategories.YOURS, y: sumResults.improvedEmissions, label: datumLabel },
                    { x: ChartCategories.OPTIMIZED, y: sumResults.optimizedEmissions, label: datumLabel },
                ]}
                labelComponent={
                    <VictoryLabel
                        style={[
                            {
                                fill: colors["starlight-blue"],
                                fontFamily: "Proxima Nova, Open Sans, Gill Sans MT, Gill Sans, Corbel, Arial, sans-serif",
                                fontWeight: 600,
                                fontSize: graphFontSize
                            }
                        ]}
                        dx={-45}
                        textAnchor="start"
                    />
                }
            />
        </VictoryChart>
    </div>;
}

export default Graph;