import Accordion from "react-bootstrap/Accordion";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import React, {useEffect, useId, useState} from "react";
import Row from "react-bootstrap/Row";
import ToggleButton from "react-bootstrap/ToggleButton";
import ToggleButtonGroup from "react-bootstrap/ToggleButtonGroup";
import {ERMCheckboxGroup} from "components/common/CheckboxGroup/CheckboxGroup";
import {ERMId, ERMs, ERMSelections, ERMTypes} from "types/ERMType";
import {useCalculatorInputContext, useCalculatorInputDispatchContext} from "contexts/CalculatorInputContext";
import {useStepDispatchContext} from "contexts/StepContext";
import {Card, ListGroup} from "react-bootstrap";
import {ERMIsValidForBuildingType} from "workers/helper";
import {BuildingTypeId} from "etc/BuildingTypes";
import ScreenHeader from "components/common/ScreenHeader/ScreenHeader";
import Col from "react-bootstrap/Col";
import ContentRow from "components/helpers/Grid/ContentRow";
import {scrollToTop, updateHeight} from "utils/iframe";
import {AccordionSelectCallback} from "react-bootstrap/AccordionContext";
import SCSSModule from "../Step6/Step6.module.scss";
import InfoPopover from "components/common/InfoPopover/InfoPopover";
import ancillaryContentDictionary, {TooltipACDT} from "components/common/AncillaryContentModal/AncillaryContent";
import StepNavigation from "components/common/StepNavigation/StepNavigation";
import GutterInfo from "components/common/InfoPopover/GutterInfo";

function Step6(): React.ReactElement {
    const stepDispatch = useStepDispatchContext();
    const calculatorInput = useCalculatorInputContext();
    const calculatorInputDispatch = useCalculatorInputDispatchContext();

    const systemImprovements: CheckboxGroupType<ERMId> = new Map();
    const performanceImprovements: CheckboxGroupType<ERMId> = new Map();

    let ERMsInput:ERMSelections = new Map();
    const [flexibleLoad, setFlexibleLoad] = useState<boolean>(true);

    useEffect(() => {
        updateHeight();
        scrollToTop();
    }, []);

    const onAccordionToggle:AccordionSelectCallback = (_eventKey, _event) => {
        setTimeout(updateHeight, 325);
    }

    function goToPrev() {
        stepDispatch({type: "goToPrev"});
    }

    function goToNext() {
        calculatorInputDispatch({
            type: "setFlexibleLoadValue",
            value: flexibleLoad
        });
        calculatorInputDispatch({
            type: "setERMsInput",
            value: ERMsInput
        });
        stepDispatch({type: "goToNext"});
    }

    function onChangeFlexibleLoad(value: number): void {
        setFlexibleLoad(Boolean(value));
    }

    function onChangeSystemImprovements(checkedValues: CheckedValuesMap<ERMId>): void {
        checkedValues.forEach((value: boolean, key: ERMId) => {
            ERMsInput.set(key, Boolean(value));
        });
    }

    function onChangePerformanceImprovements(checkedValues: CheckedValuesMap<ERMId>): void {
        checkedValues.forEach((value: boolean, key: ERMId) => {
            ERMsInput.set(key, Boolean(value));
        });
    }

    Array.from(ERMs.entries()).forEach((entry) => {
        if (typeof calculatorInput?.BuildingInputContextValue?.BuildingTypeId === "undefined") {
            throw new Error('BuildingTypeId must be set by this point');
        } else {
            if (ERMIsValidForBuildingType(entry[0], calculatorInput.BuildingInputContextValue.BuildingTypeId as BuildingTypeId)) {
                if (entry[1].type === ERMTypes.typeSystem) {
                    systemImprovements.set(entry[0], entry[1]);
                } else if (entry[1].type === ERMTypes.typePerformance) {
                    performanceImprovements.set(entry[0], entry[1]);
                }
            }
        }
    });

    const defaultSelectionText:string = "Select one or multiple";
    const [systemSelectionText, setSystemSelectionText] = useState<string>(defaultSelectionText);
    const [performanceSelectionText, setPerformanceSelectionText] = useState<string>(defaultSelectionText);

    const systemImprovementsProps: CheckboxGroupProps<ERMId> = {
        checkboxes: systemImprovements,
        selectAll: true,
        onChange: onChangeSystemImprovements,
        setSelectionText: setSystemSelectionText,
        defaultSelectionText: defaultSelectionText
    };

    const performanceImprovementsProps: CheckboxGroupProps<ERMId> = {
        checkboxes: performanceImprovements,
        selectAll: true,
        onChange: onChangePerformanceImprovements,
        setSelectionText: setPerformanceSelectionText,
        defaultSelectionText: defaultSelectionText
    };

    const ACDT = ancillaryContentDictionary.get(6)! as TooltipACDT;
    const tooltips = ACDT.tooltips;

    return <Container className={SCSSModule["step6"]}>
        <ScreenHeader title={"Customize your project"}
            subtitle={"Choose from multiple options to drive efficiency and reduce emissions."}
            optional={true}/>

        <ContentRow firstMainContentRow={true} rowClassName={SCSSModule["list-group-item-row"]} leftGutter={
            <GutterInfo>
                <InfoPopover title={tooltips.get(1)!.title} content={tooltips.get(1)!.content}/>
            </GutterInfo>
        }>
            <Col className={SCSSModule["flexible-loads"] + " " + SCSSModule["custom-padding"]}>
                <ListGroup as="ol" numbered>
                    <ListGroup.Item as="li">
                        <Form.Group controlId={useId()}>
                            <Row>
                                <Col className={"ps-md-0"} xl={6} md={12}>
                                    <Form.Label>Flexible loads</Form.Label>
                                    <Form.Text>A little bit of flexibility in your building operations can make a big difference in creating a clean, reliable grid. It’s one of the most important actions you can take.</Form.Text>
                                </Col>
                                <Col className={"px-md-0"} xl={6} md={12}>
                                    <ToggleButtonGroup type={"radio"} name={"flexibleLoad"} defaultValue={1}
                                                       onChange={onChangeFlexibleLoad} className={"toggle-button"}>
                                        <ToggleButton id={useId()} value={1}>Include flexible load</ToggleButton>
                                        <ToggleButton id={useId()} value={0}>Skip flexible load</ToggleButton>
                                    </ToggleButtonGroup>
                                </Col>
                            </Row>
                        </Form.Group>
                    </ListGroup.Item>
                </ListGroup>
            </Col>
        </ContentRow>
        <ContentRow firstMainContentRow={true} rowClassName={SCSSModule["list-group-item-row"]}>
            <Col className={"ps-md-3"}>
                <ListGroup as="ol" numbered>
                    <ListGroup.Item as="li" className={"li-two"}>
                        <Form.Group controlId={useId()}>
                            <Row>
                                <Col>
                                    <Form.Label>Select your building system improvements</Form.Label>
                                    <Accordion onSelect={onAccordionToggle}>
                                        <Accordion.Item eventKey="0">
                                            <Accordion.Header>
                                                <Card>
                                                    <Card.Body>
                                                        <Card.Title>Building system improvements</Card.Title>
                                                        <Card.Text>
                                                            <small>{systemSelectionText}</small>
                                                        </Card.Text>
                                                    </Card.Body>
                                                </Card>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <Form.Group controlId={useId()}>
                                                    <ERMCheckboxGroup {...systemImprovementsProps} />
                                                </Form.Group>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </Col>
                            </Row>
                        </Form.Group>
                    </ListGroup.Item>
                </ListGroup>
            </Col>
        </ContentRow>
        <ContentRow firstMainContentRow={true} rowClassName={SCSSModule["list-group-item-row"]}>
            <Col className={"ps-md-3"}>
                <ListGroup as="ol" numbered>
                    <ListGroup.Item as="li" className={"li-three"}>
                        <Form.Group controlId={useId()}>
                            <Row>
                                <Col>
                                    <Form.Label>Select your building performance improvements</Form.Label>
                                    <Accordion onSelect={onAccordionToggle}>
                                        <Accordion.Item eventKey="1">
                                            <Accordion.Header>
                                                <Card>
                                                    <Card.Body>
                                                        <Card.Title>Building performance improvements</Card.Title>
                                                        <Card.Text>
                                                            <small>{performanceSelectionText}</small>
                                                        </Card.Text>
                                                    </Card.Body>
                                                </Card>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <Form.Group controlId={useId()}>
                                                    <ERMCheckboxGroup {...performanceImprovementsProps} />
                                                </Form.Group>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </Col>
                            </Row>
                        </Form.Group>
                    </ListGroup.Item>
                </ListGroup>
            </Col>
        </ContentRow>

        <StepNavigation goToPrev={goToPrev} goToNext={goToNext} cta={"Let's see your results!"}/>
    </Container>;
}

export default Step6;