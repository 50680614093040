import {BuildingTypeId} from "etc/BuildingTypes";
import HeatingType from "types/HeatingType";
import {ERMId, ERMSelections} from "types/ERMType";
import {ProcessedInput} from "types/InputProcessorType";
import * as SFFetch from "./salesforce.fetch";
import * as SFOAuth from "./salesforce.oauth";

enum SFLeadDefaultValues {
    "LastName" = "AnonLead",
    "Company" = "AnonCompany",
}

type SFLeadBaseInput = {
    buildingTypeId: BuildingTypeId,
    buildingArea: number,
    existingElectricConsumption: number,
    existingGasConsumption: number,
    ERMSelections: ERMSelections,
    spaceHeatingType: HeatingType,
    DHWHeatingType: HeatingType,
    roofArea: number,
    PVPotential: number | false,
    UnitCount: number | false,
    FlexLoad: boolean,
    ResultsURL: string,
    "LastName": SFLeadDefaultValues.LastName | string,  // Required by SF, even for Anon Leads
    "Company": SFLeadDefaultValues.Company | string     // Required by SF, even for Anon Leads
}

type SFLeadIdentifiedInput = {
    "Email": string,
    "FirstName": string,
    "LastName": string,
    "Company"?: string,
    "Phone"?: string,
    "Title"?: string,
}

type SFLeadInputPatch = Partial<SFLeadBaseInput & SFLeadIdentifiedInput>

enum SFLeadBoolean {
    "true" = "Yes",
    "false" = "No",
}

const getBooleanValue = (value:any):SFLeadBoolean => SFLeadBoolean[Boolean(value).toString() as keyof typeof SFLeadBoolean];

enum SFLeadHeatingType {
    "electric" = "Electric",
    "gas" = "Natural gas",
}

enum SFLeadFlexLoad {
    "true" = "Include flexible load",
    "false" = "Skip flexible load",
}

enum SFPerformanceImprovements {
    "03" = "Window upgrade",
    "04" = "Wall upgrade",
    "05" = "Roof upgrade",
    "06" = "Infiltration reduction",
    "07" = "Heat recovery ventilation",
    "08" = "Lighting power reductions",
    "09" = "All-electric commercial cooking",
    "010" = "All-electric residential cooking",
    "011" = "High-efficiency heat pump dryer"
}

type SFLeadPerformanceImprovements = SFPerformanceImprovements[] | string

enum SFSystemImprovements {
    "01" = "Efficient electric heat pump heating",
    "02" = "Efficient electric heat pump hot water",
}

type SFLeadSystemImprovements = SFSystemImprovements[] | string

enum SFBuildingType {
    "00" = "Commercial office building",
    "01" = "Educational facility",
    "02" = "Multifamily high-rise",
    "03" = "Mid-rise without a restaurant",
    "04" = "Mid-rise with a restaurant",
}

type SFLeadBase = {
    RecordTypeId: "0128W000000OS8HQAW",
    ExistingElectricConsumption__c: number,
    ExistingGasConsumption__c: number,
    AnonLead__c: SFLeadBoolean,
    BuildingArea__c: number,
    BuildingPerformanceImprovements__c: SFLeadPerformanceImprovements,
    BuildingSystemImprovements__c: SFLeadSystemImprovements,
    BuildingTypeId__c: SFBuildingType,
    ConsideringSolar__c: SFLeadBoolean,
    DHWHeatingType__c: SFLeadHeatingType,
    FlexLoad__c: SFLeadFlexLoad,
    SpaceHeatingType__c: SFLeadHeatingType,
    URL__c: string,
    RoofArea__c: number,
    UnitCount__c?: number
}

type SFLeadIdentified = SFLeadIdentifiedInput & {
    "AnonLead__c": SFLeadBoolean,
}

type SFLeadPatch = Partial<SFLeadBase & SFLeadIdentified>

const getSFLead = (input:SFLeadBaseInput): SFLeadBase => {
    return {
        ...getSFLeadPatch(input),
        "RecordTypeId": "0128W000000OS8HQAW",
        "AnonLead__c": SFLeadBoolean["true"],
        "BuildingArea__c": input.buildingArea,
    } as SFLeadBase;
}

const getSFLeadIdentified = (input:SFLeadIdentifiedInput): SFLeadIdentified => {
    return {
        ...input,
        LastName: input.LastName || SFLeadDefaultValues.LastName,
        Company: input.Company || SFLeadDefaultValues.Company,
        "AnonLead__c": SFLeadBoolean["false"]
    }
}

const getSFLeadPatch = (input:SFLeadInputPatch): SFLeadPatch => {
    let output:SFLeadPatch = {};

    if (typeof input.buildingTypeId !== "undefined") {
        output.BuildingTypeId__c = SFBuildingType["0".concat(input.buildingTypeId) as keyof typeof SFBuildingType];
    }

    if (typeof input.ERMSelections !== "undefined") {
        const performanceImprovements:SFLeadPerformanceImprovements = Array.from(input.ERMSelections.entries()).map(([ERMId, value]:[ERMId, boolean]) => (
            value ? SFPerformanceImprovements["0".concat(ERMId.toString()) as keyof typeof SFPerformanceImprovements] ?? false : false
        )).filter(Boolean).join(";");

        const systemImprovements:SFLeadSystemImprovements = Array.from(input.ERMSelections.entries()).map(([ERMId, value]:[ERMId, boolean]) => (
            value ? SFSystemImprovements["0".concat(ERMId.toString()) as keyof typeof SFSystemImprovements] ?? false : false
        )).filter(Boolean).join(";");

        if (performanceImprovements) {
            output.BuildingPerformanceImprovements__c = performanceImprovements;
        }

        if (systemImprovements) {
            output.BuildingSystemImprovements__c = systemImprovements;
        }
    }

    if (typeof input.spaceHeatingType !== "undefined") {
        output.SpaceHeatingType__c = SFLeadHeatingType[input.spaceHeatingType];
    }

    if (typeof input.DHWHeatingType !== "undefined") {
        output.DHWHeatingType__c = SFLeadHeatingType[input.DHWHeatingType];
    }

    if (typeof input.PVPotential !== "undefined") {
        output.ConsideringSolar__c = getBooleanValue(input.PVPotential);
    }

    if (typeof input.UnitCount == "number") {
        output.UnitCount__c = input.UnitCount;
    }

    if (typeof input.FlexLoad !== "undefined") {
        output.FlexLoad__c = SFLeadFlexLoad[input.FlexLoad.toString() as keyof typeof SFLeadFlexLoad];
    }

    for (const prop of Object.keys(input)) {
        switch (prop) {
            case "buildingArea":
                output.BuildingArea__c = input.buildingArea;
                break;
            case "existingElectricConsumption":
                output.ExistingElectricConsumption__c = input.existingElectricConsumption;
                break;
            case "existingGasConsumption":
                output.ExistingGasConsumption__c = input.existingGasConsumption;
                break;
            case "roofArea":
                output.RoofArea__c = input.roofArea;
                break;
            case "ResultsURL":
                output.URL__c = input.ResultsURL;
                break;
            case "Email":
                output.Email = input.Email;
                break;
            case "FirstName":
                output.FirstName = input.FirstName;
                break;
            case "LastName":
                output.LastName = input.LastName;
                break;
            case "Company":
                output.Company = input.Company;
                break;
            case "Phone":
                output.Phone = input.Phone;
                break;
            case "Title":
                output.Title = input.Title;
                break;
        }
    }

    return output;
}

type POSTCreateSuccess = {
    id: string,
    success: boolean,
    errors: any[]
}

type POSTError = [
    {
        message: string,
        errorCode: "INVALID_SESSION_ID" | string
    }
]

type POSTCreateResponse = POSTCreateSuccess | POSTError;

const POSTResponseIsError = (response: POSTCreateResponse): response is POSTError => {
    return Array.isArray(response) && response[0] && "errorCode" in response[0];
};

const lsLeadIdKey = 'pae-lead-id';
let tokenInstance:SFOAuth.Factory;

export const createLead = async (input:ProcessedInput, resultUrl:ResultsURL, retryCount:number=0): Promise<any> | never => {
    if (retryCount > 1) {
        console.warn("Maximum number of retries reached.");
        return;
    }

    if (localStorage.getItem(lsLeadIdKey)) {
        console.warn("Lead ID already exists. Skipping.");
        return;
    }

    if (!tokenInstance) {
        tokenInstance = new SFOAuth.Factory();
    }

    const leadInput:SFLeadBaseInput = {
        buildingTypeId: input.buildingTypeId,
        buildingArea: input.buildingArea,
        existingElectricConsumption: input.existingElectricConsumption ?? 0,
        existingGasConsumption: input.existingGasConsumption ?? 0,
        ERMSelections: input.ERMSelections,
        spaceHeatingType: input.spaceHeatingType,
        DHWHeatingType: input.DHWHeatingType,
        roofArea: input.roofArea || 0,
        PVPotential: input.PVPotential,
        UnitCount: input.unitCount,
        FlexLoad: input.flexibleLoad,
        ResultsURL: resultUrl,  // resultUrl.split('#')[1] ?? ""
        LastName: SFLeadDefaultValues.LastName,
        Company: SFLeadDefaultValues.Company
    }

    const body = getSFLead(leadInput) as SFFetch.BodyInput;
    const token = await tokenInstance.getToken();
    const headers:HeadersInit = {
        "Authorization": `Bearer ${token}`,
        "Content-Type": "application/json"
    };

    const json = await SFFetch.Fetch({
        path: SFFetch.LeadPath.CreateLead,
        body: body,
        headers: headers
    }) as POSTCreateResponse;

    if (typeof json === "undefined") {
        throw new Error("Something went wrong when creating the lead.");
    } else if (POSTResponseIsError(json)) {
        if (json[0].errorCode === "INVALID_SESSION_ID") {
            // Remove old tokens
            tokenInstance.unsetToken();

            // Try again. This triggers another token lookup.
            return await createLead(input, resultUrl, retryCount + 1);
        } else {
            throw new Error(json[0].errorCode);
        }
    } else {
        localStorage.setItem(lsLeadIdKey, json.id);
    }
}

export const identifyLead = async (input:SFLeadIdentifiedInput, retryCount:number=0): Promise<void> => {
    if (retryCount > 1) {
        console.warn("Maximum number of retries reached.");
        return;
    }

    if (!tokenInstance) {
        tokenInstance = new SFOAuth.Factory();
    }

    const leadId = localStorage.getItem(lsLeadIdKey);

    if (!leadId) {
        throw new Error('Missing Lead ID.');
    }

    const leadInput = getSFLeadIdentified(input);
    const body = leadInput as SFFetch.BodyInput;
    const token = await tokenInstance.getToken();
    const headers:HeadersInit = {
        "Authorization": `Bearer ${token}`,
        "Content-Type": "application/json"
    };

    const json = await SFFetch.Fetch({
        path: SFFetch.LeadPath.PatchLead,
        id: leadId,
        body: body,
        headers: headers
    }) as POSTCreateResponse;

    if (typeof json === "undefined") {
        // Actually, this is what success looks like when PATCHing
    } else if (POSTResponseIsError(json)) {
        if (json[0].errorCode === "INVALID_SESSION_ID") {
            // Remove old tokens
            tokenInstance.unsetToken();

            // Try again. This triggers another token lookup.
            return await identifyLead(input, retryCount + 1);
        } else {
            throw new Error(json[0].errorCode);
        }
    }
}

export const resetLead = () => {
    localStorage.removeItem(lsLeadIdKey);
}
