import React, {createContext, useContext, useEffect, useReducer} from "react";

export type StepValue = 1 | 2 | 3 | 4 | 5 | 6 | 7;

function isStepValue(value: number): value is StepValue {
    return value >= 1 && value <= 7;
}

interface AbsoluteSteppingAction {
    type: "goToStep",
    stepValue: StepValue
}

interface RelativeSteppingAction {
    type: "goForward" | "goBackward",
    numSteps: number
}

interface SimpleSteppingAction {
    type: "goToNext" | "goToPrev";
}

export type SteppingAction = SimpleSteppingAction | RelativeSteppingAction | AbsoluteSteppingAction;

export function stepContextReducer(currentStepValue: StepValue, action: SteppingAction): StepValue {
    switch (action.type) {
        case "goToStep":
            if (!isStepValue(action.stepValue)) {
                throw new Error(`Cannot go to invalid step: ${action.stepValue}`);
            }
            return action.stepValue;
        case "goForward":
            return stepContextReducer(
                currentStepValue,
                {
                    type: "goToStep",
                    stepValue: (currentStepValue + action.numSteps) as StepValue
                }
            );
        case "goBackward":
            return stepContextReducer(
                currentStepValue,
                {
                    type: "goToStep",
                    stepValue: (currentStepValue - action.numSteps) as StepValue
                }
            );
        case "goToNext":
            return stepContextReducer(
                currentStepValue,
                {
                    type: "goToStep",
                    stepValue: (currentStepValue + 1) as StepValue
                }
            );
        case "goToPrev":
            return stepContextReducer(
                currentStepValue,
                {
                    type: "goToStep",
                    stepValue: (currentStepValue - 1) as StepValue
                }
            );
        default:
            throw new Error(`Invalid action type ${action["type"]}`);
    }
}

const defaultStep: StepValue = 1;
const StepContext = createContext<StepValue>(1);
const StepDispatchContext = createContext(null as unknown as React.Dispatch<SteppingAction>);


export function StepContextProvider({children}: { children: React.ReactElement[] }) {
    const [stepValue, dispatch] = useReducer(
        stepContextReducer,
        defaultStep
    );

    useEffect(() => {
        document.addEventListener('pge.trigger-loading', () => {
            dispatch({
                type: "goToStep",
                stepValue: 7
            });
        })
    }, [dispatch]);

    return (
        <StepContext.Provider value={stepValue}>
            <StepDispatchContext.Provider value={dispatch}>
                {children}
            </StepDispatchContext.Provider>
        </StepContext.Provider>
    );
}

export function useStepContext() {
    return useContext(StepContext);
}

export function useStepDispatchContext() {
    return useContext(StepDispatchContext);
}