type BuildingTypeId = import("etc/BuildingTypes").BuildingTypeId;

function getBuildingTypeClass(buildingType:BuildingTypeId): string {
    switch (buildingType) {
        case "0":
            return "commercial_office";
        case "1":
            return "educational_facility";
        case "2":
            return "multifamily_highrise";
        case "3":
            return "midrise_without_restaurant";
        case "4":
            return "midrise_including_restaurant";
    }
}

export default getBuildingTypeClass;