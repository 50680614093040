import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import InfoPopover from "components/common/InfoPopover/InfoPopover";
import React, {useState, useEffect} from "react";
import Row from "react-bootstrap/Row";
import {BuildingTypeId, BuildingType, buildingTypes} from "etc/BuildingTypes";
import {Card} from "react-bootstrap";
import {useBuildingInputDispatchContext} from "contexts/BuildingContext";
import {useCalculatorInputContext} from "contexts/CalculatorInputContext";
import {useStepDispatchContext} from "contexts/StepContext";
import ScreenHeader from "components/common/ScreenHeader/ScreenHeader";
import ContentRow from "components/helpers/Grid/ContentRow";
import InterestModal from "./Modal/InterestModal";
import SCSSModule from "./Step1.module.scss";
import ancillaryContentDictionary, {TooltipACDT} from "components/common/AncillaryContentModal/AncillaryContent";
import {resetLead} from "utils/salesforce";
import iconMultifamilyHighRise from './images/multifamily_high_rise.svg';
import iconMidriseBuildingWithoutARestaurant from './images/midrise_building_without_a_restaurant.svg';
import iconMidriseBuildingIncludingARestaurant from './images/midrise_building_including_a_restaurant.svg';
import iconCommercialOfficeBuilding from './images/commercial_office_building.svg';
import iconEducationalFacility from './images/educational_facility.svg';
import {scrollToTop, updateHeight} from "utils/iframe";
import GutterInfo from "components/common/InfoPopover/GutterInfo";

function Step1(): React.ReactElement {
    const stepDispatch = useStepDispatchContext();
    const buildingInputDispatch = useBuildingInputDispatchContext();
    const calculatorInput = useCalculatorInputContext();
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        updateHeight();
        scrollToTop();
    }, []);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    for (const [_id, buildingType] of Array.from(buildingTypes.entries())) {
        switch (buildingType.code) {
            case "multifamilyHighRise":
                buildingType.name = "Multifamily high-rise";
                buildingType.description = "High-rise residential with a mixed-use ground floor but no commercial kitchen";
                buildingType.icon = iconMultifamilyHighRise;
                buildingType.column = 0;
                break;
            case "midriseBuildingWithoutARestaurant":
                buildingType.name = "Mid-rise without a restaurant";
                buildingType.description = "Mid-rise residential with mixed-use ground floor but no commercial kitchen";
                buildingType.icon = iconMidriseBuildingWithoutARestaurant;
                buildingType.column = 0;
                break;
            case "midriseBuildingIncludingARestaurant":
                buildingType.name = "Mid-rise with a restaurant";
                buildingType.description = "Mid-rise residential with mixed-use ground floor and commercial kitchen";
                buildingType.icon = iconMidriseBuildingIncludingARestaurant;
                buildingType.column = 0;
                break;
            case "commercialOfficeBuilding":
                buildingType.name = "Commercial office building";
                buildingType.description = "Typical Class A commercial office";
                buildingType.icon = iconCommercialOfficeBuilding;
                buildingType.column = 1;
                break;
            case "educationalFacility":
                buildingType.name = "Educational facility";
                buildingType.description = "K-12 school, university classroom or campus";
                buildingType.icon = iconEducationalFacility;
                buildingType.column = 1;
                break;
        }
    }

    function selectBuildingType(event: React.MouseEvent, buildingType: BuildingType) {
        buildingInputDispatch({
            type: "setBuildingType",
            BuildingTypeId: buildingType.id
        });
        stepDispatch({type: "goToNext"});
    }

    function isActive(buildingType: BuildingTypeId) {
        return buildingType === calculatorInput.BuildingInputContextValue?.BuildingTypeId;
    }

    useEffect(() => {
        resetLead();
    }, []);

    const ACDT = ancillaryContentDictionary.get(1)! as TooltipACDT;
    const tooltips = ACDT.tooltips;

    return <>
        <Container className={SCSSModule["step1"]}>
            <ScreenHeader title={"What is your building type?"}/>

            <ContentRow firstMainContentRow={true} leftGutter={
                <GutterInfo>
                    <InfoPopover content={tooltips.get(1)!.content}/>
                </GutterInfo>
            }>
                <Col className={"px-md-0"}>
                    <Form>
                        <Container>
                            <Row>
                                {[...Array(2)].map((x, i) =>
                                    <Col key={i} xl={6} md={12} className={"selection-cards"}>
                                        {Array.from(buildingTypes.values()).map((buildingType, index) =>
                                            i === buildingType.column && (
                                                <div key={index} className={"selection-card"}>
                                                    <Card onClick={(e) => selectBuildingType(e, buildingType)} className={isActive(buildingType.id) ? "active" : ""}>
                                                        <Card.Img variant="left" src={buildingType.icon} width={35} height={35}/>
                                                        <Card.Body>
                                                            <Card.Title>{buildingType.name}</Card.Title>
                                                            <Card.Text>
                                                                {buildingType.description}
                                                            </Card.Text>
                                                        </Card.Body>
                                                    </Card>
                                                </div>
                                            )
                                        )}
                                    </Col>
                                )}
                            </Row>
                        </Container>
                    </Form>
                </Col>
            </ContentRow>

            <ContentRow rowClassName={"step-supporting-text"}>
                <Col xs={12} md={4} xl={4} className={"ps-md-0"}>
                    <span className={"supporting-text"}>Not seeing your building?</span>
                    <span className={"supporting-text-contact"}>
                        Explore our additional resources or reach out to us at <a href={"mailto:EPD@pgn.com"}>EPD@pgn.com</a>.
                    </span>
                    <Button variant="link" onClick={() => setShowModal(true)}>
                        Choose your interest
                    </Button>
                </Col>
                <Col md={4} xl={4} className={"d-none d-md-block pe-md-0 background-building"} />
            </ContentRow>
        </Container>

        <InterestModal showModal={showModal} setShowModal={setShowModal} />
    </>;
}

export default Step1;