import React, {useState} from "react";
import Modal from "react-bootstrap/Modal";
import ButtonOutlineIcon from "../ButtonOutlineIcon/ButtonOutlineIcon";
import SCSSModule from "./AncillaryContentModal.module.scss";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {ListGroup} from "react-bootstrap";
import ancillaryContentDictionary, {ACDTTypes, EducationalContentMap} from "./AncillaryContent";
import {BuildingType} from "etc/BuildingTypes";
import {useCalculatorInputContext} from "contexts/CalculatorInputContext";

function AncillaryContentModal({currentStep}:AncillaryContentModalProps): React.ReactElement | null {
    const calculatorInput = useCalculatorInputContext();
    const [showModal, setShowModal] = useState<boolean>(false);

    const ACDT = ancillaryContentDictionary.get(currentStep)!;

    if (!ACDT) {
        return null;
    }

    function getApplicableContent(content:EducationalContentMap): EducationalContentMap {
        const applicableContent:EducationalContentMap = new Map();

        Array.from(content.entries()).forEach(([index, content]) => {
            if (typeof index === "number") {
                applicableContent.set(index, content);
            } else if (typeof index === "object") {
                if (index.id === calculatorInput.BuildingInputContextValue?.BuildingTypeId) {
                    applicableContent.set(index, content);
                }
            }
        })

        return applicableContent;
    }

    function getContentId(contentId:number | BuildingType): number {
        if (typeof contentId === "object") {
            return parseInt(contentId.id);
        } else {
            return contentId as number;
        }
    }

    let tooltips = undefined;
    let content = undefined;

    if (ACDT.type === ACDTTypes.tooltip) {
        tooltips = ACDT.tooltips;
    } else if (ACDT.type === ACDTTypes.content) {
        content = getApplicableContent(ACDT.content);
    }

    return <div className={SCSSModule["ancillary-content-modal-container"]}>
        <ButtonOutlineIcon symbol={"lightbulb"} title={"Information"} onClick={() => {setShowModal(true)}} />
        <Modal show={showModal} onHide={() => {setShowModal(false)}} fullscreen={true} className={SCSSModule["ancillary-content-modal"]}>
            <Container>
                <Row>
                    <Col className={SCSSModule["header-col"]}>
                        <h4>Information</h4>
                        <ButtonOutlineIcon symbol={"close"} title={"Close"} onClick={() => {setShowModal(false)}} />
                    </Col>
                </Row>
                <Row>
                    <Col md={2} className={"d-none d-md-block"} />
                    <Col md={8}>
                        {tooltips &&
                            <ListGroup as="ol" numbered={false} className={SCSSModule["tooltips"]}>
                                {Array.from(tooltips.entries()).map(([index, tooltip]) =>
                                    <ListGroup.Item as="li" key={index}>
                                        {tooltip.title &&
                                            <h5>{tooltip.title}</h5>
                                        }
                                        <p>{tooltip.content}</p>
                                    </ListGroup.Item>
                                )}
                            </ListGroup>
                        }
                        {content &&
                            <ListGroup as="ol" numbered={false} className={SCSSModule["content-list"]}>
                                {Array.from(content.entries()).map(([index, content]) =>
                                    <ListGroup.Item as="li" key={getContentId(index)}>
                                        {content.title &&
                                            <h5>{content.title}</h5>
                                        }
                                        <p>{content.content}</p>
                                    </ListGroup.Item>
                                )}
                            </ListGroup>
                        }
                    </Col>
                    <Col md={2} className={"d-none d-md-block"} />
                </Row>
            </Container>
        </Modal>
    </div>;
}

export default AncillaryContentModal;
