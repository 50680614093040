import React from "react";
import {Card} from "react-bootstrap";
import SCSSModule from "./EducationalPanel.module.scss";
import {addClassName} from "utils/general";

function EducationalPanel({title, content, className}: EducationalPanelProps): React.ReactElement {
    return <Card className={addClassName(SCSSModule["educational-panel"], className ?? "")}>
        <Card.Body>
            <Card.Title>{title}</Card.Title>
            <Card.Text>{content}</Card.Text>
        </Card.Body>
    </Card>;
}

export default EducationalPanel;