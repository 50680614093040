import React, {useState, useEffect} from "react";
import ButtonOutlineIcon from "components/common/ButtonOutlineIcon/ButtonOutlineIcon";
import ButtonIconText from "components/common/ButtonIconText/ButtonIconText";
import SCSSModule from "../ActionsBlock/ActionsBlock.module.scss";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import RAF from "utils/RAF";
import {sendHash, updateHeight} from "utils/iframe";

function ActionsBlock({resultsShareURL}:ActionsBlock): React.ReactElement {
    const [showModal, setShowModal] = useState<boolean>(false);
    const resultsHash = resultsShareURL.split("#")[1] || false;

    useEffect(() => {
        if (resultsHash) {
            sendHash(`#${resultsHash}`);
        } else {
            console.error(`resultsShareURL does not contain a hash`);
        }
    }, [resultsHash]);

    const toggleModal = (showModal:boolean) => {
        setShowModal(showModal);
        RAF.setTimeout(updateHeight, 250);
    }

    return <div className={SCSSModule["actions-container"]}>
        <ButtonOutlineIcon symbol={"link"} title={"Copy results URL"}
            onClick={() => {navigator.clipboard.writeText(resultsShareURL)}}/>
        <ButtonOutlineIcon symbol={"print"} title={"Print"}
            onClick={() => {window.print()}} className={SCSSModule["print"]}/>

        {/* Baseline details */}
        <ButtonIconText symbol={"info"} title={"Modeling information"} onClick={() => {toggleModal(true)}} />
        <Modal show={showModal} onHide={() => {toggleModal(false)}} fullscreen={true} className={SCSSModule["modal-container"]}>
            <Container className={SCSSModule["container"]}>
                <Row>
                    <Col md={1} className={"d-none d-md-block"} />
                    <Col md={9}>
                        <ButtonIconText symbol={"close"} title={"Close modeling information"} onClick={() => {toggleModal(false)}} />
                    </Col>
                    <Col md={1} className={"d-none d-md-block"} />
                </Row>
                <Row className={SCSSModule["main-content"]}>
                    <Col md={3} className={"d-none d-md-block"} />
                    <Col md={6} className={SCSSModule["desk-no-padding"]}>
                        <div className={SCSSModule["center"]}>
                            <h3>Modeling information</h3>
                            <p>
                                The models used as the basis of comparison for the building performance improvements
                                are based on:
                            </p>
                        </div>
                        <div className={SCSSModule["content"]}>
                            <p>
                                The prototypical model assumptions and building configurations in this analysis are
                                primarily based on the DOE Prototype Building Models that are used in energy code
                                evaluation studies.
                            </p>
                            <p className={SCSSModule["p-body-small"]}>
                                Programmatic assumptions have been aligned directly to these models for the operational
                                schedules, equipment power densities, domestic water demand, and base building
                                configurations. Model modifications have been made to the total floor area and ground floor
                                configurations in the midrise buildings. HVAC system configurations have been modified to
                                single zone systems for all building types, in order to allow for direct comparison of
                                heat pump and non-heat pump technologies within the models.
                            </p>
                            <p className={SCSSModule["p-body-small"]}>
                                The energy code reference for all code regulated performance values in the base model
                                aligns with the 2021 Oregon Energy Efficiency Specialty Code (ASHRAE 90.1-2019).
                            </p>
                            <p className={SCSSModule["p-body-small"]}>
                                These values include the assumed base performance of: envelope assemblies, building
                                infiltration rates, lighting power densities, HVAC fan system performance, and cooling
                                efficiencies.
                            </p>
                            <p className={SCSSModule["p-body-small"]}>
                                Water heating and space heating system efficiencies for gas heated baselines are modeled
                                with an efficiency of 85%, to account for both non-condensing and condensing heating
                                technologies. Water heating and space heating system efficiencies for electric heated
                                baselines are modeled with an efficiency of 100% for electric resistance heating.
                            </p>
                        </div>
                    </Col>
                    <Col md={3} className={"d-none d-md-block"} />
                </Row>
                <Row>
                    <Col md={1} className={"d-none d-md-block"} />
                    <Col md={9} className={SCSSModule["col-hyperlink"]}>
                        Disclaimers:
                        <br/>
                        Prototypical Models: &nbsp;
                        <a href={'https://www.energycodes.gov/prototype-building-models'} target={'_blank'} rel="noreferrer">
                            https://www.energycodes.gov/prototype-building-models
                        </a>
                    </Col>
                    <Col md={1} className={"d-none d-md-block"} />
                </Row>
            </Container>
        </Modal>
    </div>
}

export default ActionsBlock;