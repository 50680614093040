import React, {useEffect, useMemo, useState} from "react";
import WorkerFactory from "workers/calculate-results-factory";
import {InputProcessorResult} from "types/InputProcessorType";
import {buildMessage, getMessageListener, WorkerProcesses} from "workers/helper";
import {
    getImportProcessorData,
    useCalculatorInputContext,
    useCalculatorInputDispatchContext
} from "contexts/CalculatorInputContext";
import {useResultsContext, useResultsDispatchContext} from "contexts/ResultsContext";
import YearlyOperatingEmissions from "./YearlyOperatingEmissions/Graph";
import SumEmissions from "./SumEmissions/Graph";
import Container from "react-bootstrap/Container";
import {ERMs, ERMSelections} from "types/ERMType";
import CtaPanel from "components/common/CtaPanel/CtaPanel";
import TreesCard from "./TreesCard/TreesCard";
import FormModal from "./Modal/FormModal";
import ContentRow from "components/helpers/Grid/ContentRow";
import Col from "react-bootstrap/Col";
import ErmEditor from "./ErmEditor/ErmEditor";
import FlexibleLoad from "./FlexibleLoad/FlexibleLoad";
import RAF from "utils/RAF";
import ContentRowSCSS from "components/helpers/Grid/ContentRow.module.scss";
import {createLead} from "utils/salesforce";
import {scrollToTop, updateHeight} from "utils/iframe";
import ActionsBlock from "./ActionsBlock/ActionsBlock";
import {outputResultURL} from "contexts/ResultsUtils";
import SCSSModule from "./Results.module.scss";
import {BuildingTypeId} from "etc/BuildingTypes";
import EmissionCard from "./EmissionCard/EmissionCard";
import ErmApplyAll, {getUnselectedERMs} from "./ErmApplyAll/ErmApplyAll";
import Disclosures from "./Disclosures/Disclosures";
import LearnMore from "./LearnMore/LearnMore";
import SavedForestsCard from "./SavedForestsCard/SavedForestsCard";

type buildingTitle = Map<BuildingTypeId, {title: string}>;
export type ResultsURL = string;

function Results(): React.ReactElement | null {
    const calculatorInput = useCalculatorInputContext();
    const calculatorInputDispatch = useCalculatorInputDispatchContext();
    const results = useResultsContext();
    const resultsDispatch = useResultsDispatchContext();
    const [showModal, setShowModal] = useState(false);
    const selectedBuildingTypeId:BuildingTypeId | undefined = calculatorInput.BuildingInputContextValue?.BuildingTypeId;

    const buildingTitles:buildingTitle = new Map([
        ["0", {title: "Your commercial office building impact"}],
        ["1", {title: "Your educational facility building impact"}],
        ["2", {title: "Your multifamily high-rise building impact"}],
        ["3", {title: "Your mid-rise without a restaurant building impact"}],
        ["4", {title: "Your mid-rise with a restaurant building impact"}]
    ]);

    const paeWorker: Worker = useMemo(
        () => WorkerFactory(),
        []
    );

    const attemptResultsWorkerTrigger = () => {
        if (calculatorInput.minimumInputReached) {
            console.log("Minimum input reached!", "Trigger results worker");
            if (window.Worker) {
                RAF.setTimeout(function() {
                    paeWorker.postMessage(
                        buildMessage(
                            WorkerProcesses.calculatorInput,
                            getImportProcessorData(calculatorInput)
                        )
                    );
                }, 4000);
            }
        } else {
            console.debug("Minimum input not reached.");
        }
    };

    const selectAllERMs = ():void => {
        const allERMSelections:ERMSelections = new Map();
        for (const key of ERMs.keys()) {
            allERMSelections.set(key, true);
        }

        updateERMs(allERMSelections);
    };

    const updateERMs = (newERMSelections:ERMSelections) => {
        const finalERMSelections = calculatorInput.ERMsInputValue || new Map();
        newERMSelections.forEach((value, key) => {
            finalERMSelections.set(key, value);
        });

        resultsDispatch(null);
        calculatorInputDispatch({
            type: "setERMsInput",
            value: finalERMSelections
        });
    }

    const listenForResults = () => {
        if (window.Worker) {
            paeWorker.onmessage = getMessageListener(
                WorkerProcesses.calculatorResult,
                (payload: InputProcessorResult) => {
                    resultsDispatch(payload);
                }
            );
        }
    };

    useEffect(listenForResults, [paeWorker, resultsDispatch]);
    useEffect(attemptResultsWorkerTrigger, [calculatorInput, paeWorker]);

    const resultUrl:ResultsURL = useMemo(() => results ? outputResultURL(results.processedInput!) : "", [results]);

    useEffect(() => {
        let ignore = false;
        async function startFetching() {
            if (!ignore && results) {
                console.debug('start fetching', results);
                await createLead(results.processedInput!, resultUrl);
            }
        }

        if (results) {
            updateHeight();
            scrollToTop();
            void startFetching();
            return () => {
                ignore = true;
            }
        }
    }, [results, resultUrl]);

    if (!results) {
        return null;
    }

    return <Container className={SCSSModule["results"]}>
        <ContentRow>
            <Col className={"px-md-0"}>
                <div className={SCSSModule["center"]}>
                    <h3>{selectedBuildingTypeId && buildingTitles.get(selectedBuildingTypeId)?.title}</h3>
                    <p>Let’s review the result of your building selections</p>
                </div>
            </Col>
        </ContentRow>

        <ContentRow resultsPage={true}>
            <Col className={"px-md-0"}>
                <ActionsBlock resultsShareURL={resultUrl}/>
            </Col>
        </ContentRow>

        <ContentRow resultsPage={true} rowClassName={ContentRowSCSS["ContentRow-top-border"]}>
            <Col className={"px-md-0"}>
                <ErmEditor defaultSelectedERMs={results.processedInput.ERMSelections} updateERMs={updateERMs}/>
            </Col>
        </ContentRow>

        <ContentRow resultsPage={true} rowClassName={ContentRowSCSS["ContentRow-top-border"]}>
            <Col className={"px-md-0"}>
                <YearlyOperatingEmissions resultMap={results.yearlyValues}
                                          cta={"Request a consultation"}
                                          onClick={() => setShowModal(true)}/>
            </Col>
        </ContentRow>

        <ContentRow resultsPage={true}>
            <Col xl={6} md={6} className={"px-0 ps-md-0 pe-md-0"}>
                <TreesCard
                    title={"Your 20 year savings are equivalent to the carbon sequestered by nearly"}
                    {...results.sums.totalTreesEquivalent}
                />
            </Col>
            <Col xl={4} md={6} className={"px-0 ps-md-0 pe-md-0"}>
                <EmissionCard
                    title={"Estimated emissions savings over 20 years"}
                    value={results.sums.totalNetSavingGHG}
                />
            </Col>
        </ContentRow>

        <ContentRow resultsPage={true}>
            <Col className={"px-md-0"}>
                <FlexibleLoad yearOne={results.yearOne} sums={results.sums} />
            </Col>
        </ContentRow>

        {!!getUnselectedERMs(results.processedInput).length &&
            <>
                <ContentRow resultsPage={true} rowClassName={ContentRowSCSS["ContentRow-top-border"]}>
                    <Col className={"px-md-0"}>
                        <ErmApplyAll
                            processedInput={results.processedInput}
                            sumResult={results.sums}
                            selectAllERMs={selectAllERMs}
                        />
                    </Col>
                </ContentRow>

                <ContentRow resultsPage={true}>
                    <Col xl={6} md={6} className={"px-md-0"}>
                        <SumEmissions sumResults={results.sums}/>
                    </Col>
                    <Col xl={4} md={6} className={"px-md-0"}>
                        <SavedForestsCard
                            title={"Additional 20 year savings equivalent to"}
                            selectAllERMs={selectAllERMs}
                            {...results.sums.getTotalBestTreesEquivalentDiff}
                        />
                    </Col>
                </ContentRow>
            </>
        }

        <ContentRow resultsPage={true} rowClassName={ContentRowSCSS["ContentRow-top-border"]}>
            <Col className={"px-md-0"}>
                <LearnMore/>
            </Col>
        </ContentRow>

        <ContentRow resultsPage={true} rowClassName={ContentRowSCSS["ContentRow-top-border"]}>
            <Col className={"px-md-0"}>
                <CtaPanel
                    content={"Schedule a meeting with one of our energy experts"}
                    cta={"Request a consultation"}
                    onClick={() => setShowModal(true)}
                />
            </Col>
        </ContentRow>

        <ContentRow resultsPage={true}>
            <Col className={"px-md-0"}>
                <Disclosures/>
            </Col>
        </ContentRow>

        <ContentRow resultsPage={true}>
            <Col className={"px-md-0"}>
                <FormModal showModal={showModal} setShowModal={setShowModal}/>
            </Col>
        </ContentRow>
    </Container>;
}

export default Results;
