import pge from "./pge";

enum PGEMessageAction {
    SET_HEIGHT = "setHeight",
    SCROLL_TOP = "scrollIntoView",
    RELOAD = "reloadPage",
    GET_HASH = "requestUrlHashFragment",
    SET_HASH = "sendUrlHashFragment",
}

enum PGEMessageReceivable {
    RECEIVE_HASH = PGEMessageAction.SET_HASH
}

interface PGEActionInterface {
    action: PGEMessageAction
    payload?: any
}

interface PGESetHeight extends PGEActionInterface {
    action: PGEMessageAction.SET_HEIGHT,
    payload: number
}

interface PGEScrollTop extends PGEActionInterface {
    action: PGEMessageAction.SCROLL_TOP
}

interface PGEReload extends PGEActionInterface {
    action: PGEMessageAction.RELOAD
}

interface PGEGetHash extends PGEActionInterface {
    action: PGEMessageAction.GET_HASH
}

interface PGESetHash extends PGEActionInterface {
    action: PGEMessageAction.SET_HASH
    payload: string
}

type PGEAction = PGESetHeight | PGEScrollTop | PGEReload | PGEGetHash | PGESetHash;
type ReceiveHashCallback = (hash:string) => void;

const messages = new Set<string>();
let receiveHashCallback:ReceiveHashCallback;

const originsMatch = (a:string, b:string):boolean => {
    return a.toLowerCase().replace(/\/+$/, "") ===
        b.toLowerCase().replace(/\/+$/, "");
};

let listenerSet:boolean = false;
const addListener = () => {
    if (!listenerSet) {
        listenerSet = true;
        window.addEventListener('message', (event) => {
            console.groupCollapsed('PGE Calculator Message Processor');
            try {
                console.log(event);
                if (originsMatch(event.origin, pge.targetOrigin) || event.origin.toLowerCase().endsWith('portlandgeneral.com')) {
                    if (!event.data?.action || !event.data?.payload) {
                        console.log(`Message ignored: Couldn't parse the data.`);
                        return
                    } else if (!Object.values(PGEMessageReceivable).includes(event.data.action)) {
                        console.log(`Message ignored: Unknown action.`);
                        return;
                    } else {
                        switch (event.data.action) {
                            case PGEMessageReceivable.RECEIVE_HASH:
                                console.log(`We received a new hash: ${event.data.payload}`);
                                if (receiveHashCallback) {
                                    receiveHashCallback(event.data.payload);
                                } else {
                                    console.warn('receiveHashCallback was not set');
                                }
                                break;
                        }
                    }
                } else {
                    console.log(`Message ignored: the origin isn't correct.`);
                }
            } finally {
                console.groupEnd();
            }
        });
        console.log('Message listener added.');
    }
}

let postMessageTimer: ReturnType<typeof setTimeout>;

const postMessage = (messageData:PGEAction) => {
    addListener();

    console.debug('Adding message to queue', messageData);

    messages.add(JSON.stringify(messageData));

    if (postMessageTimer) {
        clearTimeout(postMessageTimer);
    }

    postMessageTimer = setTimeout(() => {
        for (const messageDataString of messages.values()) {
            // eslint-disable-next-line no-restricted-globals
            if (top) {
                console.log('Posting message', messageDataString, JSON.parse(messageDataString));
                // eslint-disable-next-line no-restricted-globals
                top.postMessage(
                    JSON.parse(messageDataString),
                    pge.targetOrigin
                );
            } else {
                console.log(`'top' is not available for posting messages`);
            }
            messages.delete(messageDataString);
        }
    }, 50);
}

const getHeight = ():number => {
    const modal: HTMLDivElement | null = document.querySelector('.modal[role="dialog"] .modal-fullscreen');
    if (modal) {
        return modal.offsetHeight;
    } else {
        return Math.max(document.body.scrollHeight, document.body.offsetHeight);
    }
}

export const updateHeight = () => {
    postMessage({
        action: PGEMessageAction.SET_HEIGHT,
        payload: getHeight()
    });
}

export const scrollToTop = () => {
    postMessage({
        action: PGEMessageAction.SCROLL_TOP
    });
}

export const sendHash = (hash:string) => {
    postMessage({
        action: PGEMessageAction.SET_HASH,
        payload: hash
    });
}

export const requestHash = (callback:ReceiveHashCallback) => {
    receiveHashCallback = callback;
    postMessage({
        action: PGEMessageAction.GET_HASH
    });
}