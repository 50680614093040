import InputProcessorType, {InputProcessorResult} from "types/InputProcessorType";
import {
    educationalFacility,
    midriseBuildingIncludingARestaurant,
    midriseBuildingWithoutARestaurant,
    multifamilyHighRise
} from "etc/BuildingTypes";

export enum WorkerProcesses {
    calculatorInput = "pae-submit-calculator-input",
    calculatorResult = "pae-post-calculator-result",
    preload = "pae-preload-worker",
    preloadResponse = "pae-preload-response",
}

interface CalculatorInputMessage {
    type: WorkerProcesses.calculatorInput,
    payload: InputProcessorType
}

interface CalculatorResponseMessage {
    type: WorkerProcesses.calculatorInput,
    payload: InputProcessorResult
}

interface SimpleMessage {
    type: WorkerProcesses,
    payload: null
}

export type WorkerMessageType = SimpleMessage | CalculatorInputMessage | CalculatorResponseMessage;

export function buildMessage(type: WorkerProcesses.calculatorInput, payload: InputProcessorType): CalculatorInputMessage
export function buildMessage(type: WorkerProcesses.calculatorResult, payload: InputProcessorResult): CalculatorResponseMessage
export function buildMessage(type: WorkerProcesses): { type: WorkerProcesses }
export function buildMessage(type: WorkerProcesses, payload?: any) {
    return {
        type: type,
        ...(payload && {payload: payload})
    };
}

type onMessageCallback = (e: MessageEvent<WorkerMessageType>) => void

export function getMessageListener(type: WorkerProcesses.calculatorResult, payloadCallback: (payload: InputProcessorResult) => void): onMessageCallback
export function getMessageListener(type: WorkerProcesses, callback: Function): onMessageCallback
export function getMessageListener(type: WorkerProcesses, payloadCallback?: Function, callback?: Function): onMessageCallback {
    return (e: MessageEvent<WorkerMessageType>) => {
        if (e.data?.type !== type) {
            throw new Error(`Unrecognized message type ${JSON.stringify(e.data?.type)}`);
        }

        if (payloadCallback && e.data?.payload) {
            payloadCallback(e.data.payload);
        } else if (callback && e.data?.payload) {
            callback(e.data.payload);
        }
    };
}

/*
 * ERMs 9, 10 and 11 are only valid for a few building types each.
 * If these ERMs are set to true, something went wrong at the user-input layer, and the values should be overridden.
 */
export function ERMIsValidForBuildingType(ERMId:ERMId, buildingTypeId:BuildingTypeId): boolean {
    switch (ERMId) {
        case 9: // All-electric commercial cooking
            return buildingTypeId === midriseBuildingIncludingARestaurant.id
                || buildingTypeId === educationalFacility.id;
        case 10: // All-electric residential cooking
        case 11: // High-efficiency heat pump dryer
            return buildingTypeId === midriseBuildingIncludingARestaurant.id
                || buildingTypeId === midriseBuildingWithoutARestaurant.id
                || buildingTypeId === multifamilyHighRise.id;
        default:
            return true;
    }
}