import React from "react";
import {useStepContext} from "contexts/StepContext";
import Step1 from "components/screens/Step1/Step1";
import Step2 from "components/screens/Step2/Step2";
import Step3 from "components/screens/Step3/Step3";
import Step4 from "components/screens/Step4/Step4";
import Step5 from "components/screens/Step5/Step5";
import Step6 from "components/screens/Step6/Step6";
import Step7 from "components/screens/Step7/Step7";

function StepRenderer({step}: { step: number }): React.ReactElement | null {
    const currentStep = useStepContext();

    /*
     * Don't render if not the current step.
     */
    if ((currentStep as number) !== step) {
        return null;
    } else {
        const stepComponents: { [n: number]: () => React.ReactElement } = {
            1: Step1,
            2: Step2,
            3: Step3,
            4: Step4,
            5: Step5,
            6: Step6,
            7: Step7
        };
        // Must be capitalized!
        const TheStep: () => React.ReactElement = stepComponents[step];
        return React.createElement(TheStep, {});
    }
}

export default StepRenderer;
