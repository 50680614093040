import React, {useId} from "react";
import Form from "react-bootstrap/Form";
import {ERMs} from "types/ERMType";
import MaterialSymbolRound from "components/common/MaterialSymbolRound/MaterialSymbolRound";
function ErmSelectorList({defaultSelectedERMs, selectedERMs, showERMsWhenDefaultIs, disabled, onChange, ...rest}:ErmSelectorListProps): React.ReactElement {
    const baseId = useId();

    return <div className={"erms"} {...rest}>
        {Array.from(selectedERMs).map(([id, selected]) => defaultSelectedERMs.get(id) === showERMsWhenDefaultIs && (
            <Form.Check.Label className={selected ? "selected" : "unselected"} key={id} htmlFor={baseId + id}>
                <span className={"erm-label"}>
                    {ERMs.get(id)?.symbol && <MaterialSymbolRound value={ERMs.get(id)?.symbol}/> }<span>{ERMs.get(id)?.label}</span>
                </span>
                <Form.Check.Input onChange={onChange} value={id}
                    id={baseId + id} checked={selected}
                    disabled={disabled}
                />
            </Form.Check.Label>
        ))}
    </div>
}

export default ErmSelectorList;