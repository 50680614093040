import React from "react";

/**
 * The options FILL, wght, GRAD and opsz will only work if their possible values are added to the font URL in index.html
 * For instance, this will only support unfilled: ?family=Material+Symbols+Rounded:FILL@0
 * while this will support either unfilled or filled: ?family=Material+Symbols+Rounded:FILL@0..1
 *
 * IMPORTANT: If you want to make a change to ALL ICONS, just change the font URL. (eg: Switching from all unfilled to
 * all filled.)
 */

function MaterialSymbolRound({value, fill, wght, grad, opsz}:MaterialSymbolProps): React.ReactElement {

    const fontVariationSettings = new Set();

    if (typeof fill !== "undefined") {
        fontVariationSettings.add(`"FILL" ` + (fill?1:0));
    }
    if (typeof wght !== "undefined") {
        fontVariationSettings.add(`"wght" ${wght}`);
    }
    if (typeof grad !== "undefined") {
        fontVariationSettings.add(`"GRAD" ${grad}`);
    }
    if (typeof opsz !== "undefined") {
        fontVariationSettings.add(`"opsz" ${opsz}`);
    }

    const style = fontVariationSettings.size ? {
        fontVariationSettings: Array.from(fontVariationSettings).join(", ")
    } : {};

    return <>
        {value && <span className="material-symbols-rounded" style={style}>{value}</span>}
    </>;
}

export default MaterialSymbolRound;