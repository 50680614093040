import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import ContentRow from "components/helpers/Grid/ContentRow";
import Modal from "react-bootstrap/Modal";
import React, {useId} from "react";
import Row from "react-bootstrap/Row";
import {Card} from "react-bootstrap";
import ScreenHeader from "components/common/ScreenHeader/ScreenHeader";
import ButtonOutlineIcon from "components/common/ButtonOutlineIcon/ButtonOutlineIcon";
import SCSSModule from "./InterestModal.module.scss";

function InterestModal({showModal, setShowModal}:{showModal:boolean, setShowModal: React.Dispatch<React.SetStateAction<boolean>>}): React.ReactElement {
    const handleCloseModal = () => setShowModal(false);
    const modalId = useId();

    const interests: Interests = new Map([
        [1, {
            label: "Commercial customer programs",
            description: "PGE programs help optimize energy use",
            url: "https://portlandgeneral.com/save-money/save-money-business",
        }],
        [2, {
            label: "Energy Partner Design",
            description: "Build your piece of the clean energy future",
            url: "https://assets.ctfassets.net/416ywc1laqmd/31ZsOhUulhDb9NahFEHn8q/baeca6171b0ff365a9b1381a9a7b8915/energy-partner-design-fact-sheet.pdf",
        }]
    ]);

    function processExternalLink(event: React.MouseEvent, url: string) {
        window.open(url, "top");
    }

    function getInterestCard(interest:Interest): React.ReactElement {
        return <div className={"selection-card-arrow"}>
            <Card onClick={(e) => processExternalLink(e, interest.url)}>
                <Card.Body>
                    <Card.Title>{interest.label}</Card.Title>
                    <Card.Text>
                        {interest.description}
                    </Card.Text>
                </Card.Body>
            </Card>
        </div>
    }

    return <Modal
        show={showModal}
        onHide={handleCloseModal}
        fullscreen={true}
        aria-labelledby={modalId}
    >
        <Container className={SCSSModule["interest-modal"]}>
            <ContentRow rowClassName={"modal-close"}>
                <Col className={"px-md-0"}>
                    <ButtonOutlineIcon symbol={"arrow_back"} title={"Close"} onClick={() => {setShowModal(false)}} />
                </Col>
            </ContentRow>

            <ScreenHeader title={"Choose your interest"}/>

            <ContentRow rowClassName={"modal-body"}>
                <Col className={"px-md-0"}>
                    <Row>
                        <Col xl={6}>
                            {getInterestCard(interests.get(1)!)}
                        </Col>
                        <Col xl={6}>
                            {getInterestCard(interests.get(2)!)}
                        </Col>
                    </Row>
                </Col>
            </ContentRow>

            <ContentRow rowClassName={"modal-supporting-text"}>
                <Col xs={9} md={6} xl={4} className={"ps-md-0"}>
                    <span className={"supporting-text"}>Still not seeing what you are looking for?</span>
                    <Button variant="link" onClick={handleCloseModal}>
                        Back to the calculator
                    </Button>
                </Col>
                <Col xs={3} md={2} xl={4} className={"d-none d-md-block pe-md-0"} />
            </ContentRow>
        </Container>
    </Modal>;
}

export default InterestModal;