import React from "react";
import SCSSModule from "./StepNavigation.module.scss";
import Col from "react-bootstrap/Col";
import ContentRow from "components/helpers/Grid/ContentRow";
import ButtonOutlineIcon from "../ButtonOutlineIcon/ButtonOutlineIcon";
import PseudoDisablableButton from "../PseudoDisablableButton/PseudoDisablableButton";

function StepNavigation({goToPrev, goToNext, nextAvailable=true, nextDisabled=false, cta="Continue"}: StepNavigationProps): React.ReactElement {
    return <ContentRow rowClassName={SCSSModule["screen-buttons-row"]}>
        <Col className={"screen-buttons-col"}>
            <ButtonOutlineIcon symbol={"arrow_back"} onClick={goToPrev} title={"Back"} />
            {nextAvailable && <PseudoDisablableButton onClick={goToNext} disabled={nextDisabled}>{cta}</PseudoDisablableButton>}
        </Col>
    </ContentRow>;
}

export default StepNavigation;