import React from "react";
import {Card} from "react-bootstrap";
import SCSSModule from "./EmissionCard.module.scss";

function EmissionCard({title, value}: EmissionCardProps): React.ReactElement {

    return (
        <Card className={SCSSModule["trees-card"]}>
            <Card.Body>
                <Card.Title>{title}</Card.Title>
                <Card.Text>
                    <span>{Math.floor(value).toLocaleString()}</span>
                    <span>MTCO2e</span>
                </Card.Text>
            </Card.Body>
        </Card>
    );
}

export default EmissionCard;