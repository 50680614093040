import React from "react";
import SCSSModule from "./ErmApplyAll.module.scss";
import {ERMs, ERMId} from "types/ERMType";
import {ERMIsValidForBuildingType} from "workers/helper";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";

let unselectedERMIds:ERMId[];
export const getUnselectedERMs = (processedInput: ProcessedInput): ERMId[] => {
    unselectedERMIds = [];
    for (const [id, selected] of processedInput.ERMSelections.entries()) {
        if (!selected && ERMIsValidForBuildingType(id, processedInput.buildingTypeId)) {
            unselectedERMIds.push(id);
        }
    }

    return unselectedERMIds;
};

function ErmApplyAll({processedInput, sumResult, selectAllERMs}: ErmApplyAllProps): React.ReactElement | null {
    const optimizedSavings = sumResult.totalReferenceGHG - sumResult.totalBestGHG;
    const optimizedPVSavings = sumResult.totalPVGHGOffset;
    const availableSavings = optimizedSavings + optimizedPVSavings;

    if (typeof unselectedERMIds === "undefined") {
        console.warn(`ErmApplyAll  must be wrapped in a call to getUnselectedERMs`);
        return null
    }

    return (
        <Container className={SCSSModule["erm-apply-all"]}>
            <Row>
                <Col xl={6} md={6} className={"ps-md-0"}>
                    <span className={SCSSModule["title"]}>Upgrade your 20 year impact</span>
                    <span className={SCSSModule["subtitle"]}>You can still save {Math.floor(availableSavings).toLocaleString()} MTCO2e</span>
                    <Button variant="primary" className={"on-yellow"} onClick={selectAllERMs}>Apply all building improvements</Button>
                </Col>
                <Col xl={6} md={6} className={"pe-md-0"}>
                    <p>Apply the following building adjustments to see full savings impacts:</p>
                    <ul>
                        {getUnselectedERMs(processedInput).map((id, index) =>
                            <li key={index}>{ERMs.get(id as ERMId)?.label}</li>
                        )}
                    </ul>
                </Col>
            </Row>
        </Container>
    );
}

export default ErmApplyAll;